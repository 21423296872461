import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { isExist } from 'util/helper';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/reset-password',
	components: () => [import('./component')],
	render: ([ResetPassword]) => <ResetPassword />,
	onEnter: async ({ store, history }) => {
		const {
			auth: {
				signUpForm
			}
		} = store.getState();

		// 重設密碼的前提是已經驗證過手機號碼，所以沒有 signUpForm.phone.value 的情況下，直接導回首頁
		if (!isExist(signUpForm.phone.value)) {
			history.push('/');
		}
	},
};
export default routes;
