import { clearForm } from 'models/auth';
import React from 'react';
import { isExist } from 'util/helper';
import { CustomRoute } from 'util/hook/useRouter';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/signin',
	components: () => [import('./component')],
	render: ([Signin]) => <Signin />,
	onEnter: async ({ history, store }) => {
		const { member: { userInfo } } = store.getState();

		store.dispatch(clearForm('signInForm'))
		if (isExist(userInfo?.id)) {
			history.replace('/member-centre');
		}
	},
};
export default routes;
