import { getLineAuthToken } from 'models/auth';
import { getUserInfo } from 'models/member';
import React from 'react';
import { isExist } from 'util/helper';
import { CustomRoute } from 'util/hook/useRouter';
import { parseStateFromLineQuery } from 'util/line';
import { parseQueryString } from 'util/parseQueryString';
import storage from 'util/storage';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/line-auth',
	components: () => [import('./component')],
	render: ([LineAuth]) => <LineAuth />,
	onEnter: async ({ next, store }) => {
		const { code } = parseQueryString();
		const token = storage.getToken();

		if (isExist(token)) {
			store.dispatch(getUserInfo());
		}

		if (code) {
			const stateDataFromQuery = parseStateFromLineQuery();
			storage.setItem('line-from', stateDataFromQuery.query.split('/')[1])
			await store.dispatch(getLineAuthToken(code, 'register'));

		}
		const children = await next();
		return children;
	},
};
export default routes;
