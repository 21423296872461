import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	V1NewsListRequestParams,
	NewsIndexResource,
	NewsResource,
	NewsCategoriesResource,
} from 'util/api/swaggerApi/data-contracts';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export interface MetaDataProperty {
	currentPage?: number;
	from?: number;
	lastPage?: number;
	path?: string;
	perPage?: number;
	to?: number;
	total?: number;
}

/**
 * 取得所有最新消息清單
 */
export const getAllNewsList = createAction(
	'GET_ALL_NEWS_LIST',
	async (params: V1NewsListRequestParams) => {
		try {
			const { v1NewsList } = api;
			const { data } = await v1NewsList(params);
			return { data: data?.data, metaData: data?.meta };
		} catch (e) {
			return [];
		}
	},
);

/**
 * 依分類取得最新消息清單
 */
export const getNewsListByCategory = createAction(
	'GET_NEWS_LIST_BY_CATEGORY',
	async (params: V1NewsListRequestParams) => {
		try {
			const { v1NewsList } = api;
			const { data } = await v1NewsList(params);
			return { categoryId: params.category_id, data: data?.data, metaData: data?.meta };
		} catch (e) {
			return { categoryId: params.category_id, newsList: [] };
		}
	},
);

/**
 * 取得最新消息內頁
 */
export const getNewsListByID = createAction('GET_NEWS_LIST_BY_ID', async (id: number) => {
	try {
		const { v1NewsDetail } = api;
		const { data } = await v1NewsDetail(id);
		return data?.data;
	} catch (e) {
		return [];
	}
});

/**
 * 取得最新消息分類
 */
export const getNewsCategory = createAction('GET_NEWS_CATEGORY', async () => {
	try {
		const { v1NewsCategoriesList } = api;
		const { data } = await v1NewsCategoriesList();
		return data?.data;
	} catch (e) {
		return [];
	}
});

export interface State {
	loading: boolean;
	newsCategory: NewsCategoriesResource[];
	newsList: { [categoryId: number]: NewsIndexResource[] };
	allNewsList: NewsIndexResource[];
	newsData: NewsResource;
	metaData: MetaDataProperty;
}

export const defaultState: State = {
	loading: false,
	newsCategory: [],
	newsList: {},
	allNewsList: [],
	newsData: {},
	metaData: {},
};

export const reducer = {
	news: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ALL_NEWS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ALL_NEWS_LIST_FULFILLED: (state, action) => ({
				...state,
				allNewsList: action.payload.data,
				metaData: action.payload.metaData,
				loading: false,
			}),

			GET_NEWS_LIST_BY_CATEGORY_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_NEWS_LIST_BY_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				newsList: {
					...state.newsList,
					[action.payload.categoryId]: action.payload.data,
				},
				metaData: action.payload.metaData,
				loading: false,
			}),

			GET_NEWS_LIST_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_NEWS_LIST_BY_ID_FULFILLED: (state, action) => ({
				...state,
				newsData: action.payload,
				loading: false,
			}),

			GET_NEWS_CATEGORY_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_NEWS_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				newsCategory: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const newsActionsMap = {
	getNewsCategory,
	getAllNewsList,
	getNewsListByCategory,
	getNewsListByID,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.news.loading,
	(state: GlobalState) => state.news.newsCategory,
	(state: GlobalState) => state.news.newsList,
	(state: GlobalState) => state.news.allNewsList,
	(state: GlobalState) => state.news.newsData,
	(state: GlobalState) => state.news.metaData,
	(loading, newsCategory, newsList, allNewsList, newsData, metaData) => ({
		loading,
		newsCategory,
		newsList,
		allNewsList,
		newsData,
		metaData,
	}),
);

type NewsSelector = ReturnType<typeof mapHooksToState>;
type NewsActionsMap = typeof newsActionsMap;

export const useNews = () =>
	useRedux<NewsSelector, NewsActionsMap>(mapHooksToState, newsActionsMap);
