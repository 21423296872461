import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getNewsCategory } from 'models/news';

import NewsDetailRoute from './NewsDetail';

const routes: CustomRoute = {
	path: '/news',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([News]) => <News />,
			onEnter: async ({ next, store }) => {
				const children = await next();
				store.dispatch(getNewsCategory());
				return children;
			},
		},
		NewsDetailRoute,
	],
};
export default routes;
