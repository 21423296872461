import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTopicsCategory } from 'models/topics';

import TopicsDetailRoute from './TopicsDetail';

const routes: CustomRoute = {
	path: '/topics',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Topics]) => <Topics />,
			onEnter: async ({ next, store }) => {
				const children = await next();
				store.dispatch(getTopicsCategory());
				return children;
			},
		},
		TopicsDetailRoute,
	],
};
export default routes;
