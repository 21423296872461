import React from 'react';

import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';

interface ButtonLoginProperty {
	className?: string;
	fullWidth?: boolean;
}

const ButtonLogin: React.FC<ButtonLoginProperty> = ({ className, fullWidth }) => (
	<Link to="/signin">
		<Button className={className} outline fullWidth={fullWidth}>
			登入 / 註冊
		</Button>
	</Link>
);

export default ButtonLogin;
