import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

interface ButtonScrollTopProperty {
	className?: string;
}

const ButtonScrollTop: React.FC<ButtonScrollTopProperty> = ({ className }) => (
	<button
		type="button"
		className={classnames(styles.buttonScrollTop, className)}
		onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
	>
		Top
	</button>
);

export default ButtonScrollTop;
