import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	V1BlogListRequestParams,
	BlogIndexResource,
	BlogResource,
	BlogCategoriesResource,
	BlogIndexPageResource,
} from 'util/api/swaggerApi/data-contracts';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export interface MetaDataProperty {
	currentPage?: number;
	from?: number;
	lastPage?: number;
	path?: string;
	perPage?: number;
	to?: number;
	total?: number;
}

/**
 * 取得所有編輯精選清單
 */
export const getAllTopicsList = createAction(
	'GET_ALL_TOPICS_LIST',
	async (params: V1BlogListRequestParams) => {
		try {
			const { v1BlogList } = api;
			const { data } = await v1BlogList(params);
			return { data: data?.data, metaData: data?.meta };
		} catch (e) {
			return [];
		}
	},
);

/**
 * 依分類取得編輯精選清單
 */
export const getTopicsListByCategory = createAction(
	'GET_TOPICS_LIST_BY_CATEGORY',
	async (params: V1BlogListRequestParams) => {
		try {
			const { v1BlogList } = api;
			const { data } = await v1BlogList(params);
			return { categoryId: params.category_id, data: data?.data, metaData: data?.meta };
		} catch (e) {
			return { categoryId: params.category_id, newsList: [] };
		}
	},
);

/**
 * 取得編輯精選內頁
 */
export const getTopicsListByID = createAction('GET_TOPICS_LIST_BY_ID', async (id: number) => {
	try {
		const { v1BlogDetail } = api;
		const { data } = await v1BlogDetail(id);
		return data?.data;
	} catch (e) {
		return [];
	}
});

/**
 * 取得編輯精選分類
 */
export const getTopicsCategory = createAction('GET_TOPICS_CATEGORY', async () => {
	try {
		const { v1BlogCategoriesList } = api;
		const { data } = await v1BlogCategoriesList();
		return data?.data;
	} catch (e) {
		return [];
	}
});

/**
 * 取得首頁上要顯示的編輯精選分類
 */
export const getTopicsForHomePage = createAction('GET_TOPICS_FOR_HOME_PAGE', async () => {
	try {
		const { v1BlogIndexPageList } = api;
		const { data } = await v1BlogIndexPageList();
		return data?.data;
	} catch (e) {
		return [];
	}
});

export interface State {
	loading: boolean;
	topicsList: { [categoryId: number]: BlogIndexResource[] };
	allTopicsList: BlogIndexResource[];
	topicsData: BlogResource;
	metaData: MetaDataProperty;
	topicsCategory: BlogCategoriesResource[];
	topicsListForHomePage: BlogIndexPageResource[];
}

export const defaultState: State = {
	loading: false,
	topicsList: {},
	allTopicsList: [],
	topicsData: {},
	metaData: {},
	topicsCategory: [],
	topicsListForHomePage: [],
};

export const reducer = {
	topics: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ALL_TOPICS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ALL_TOPICS_LIST_FULFILLED: (state, action) => ({
				...state,
				allTopicsList: action.payload.data,
				metaData: action.payload.metaData,
				loading: false,
			}),

			GET_ALL_TOPICS_LIST_BY_CATEGORY_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOPICS_LIST_BY_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				topicsList: {
					...state.topicsList,
					[action.payload.categoryId]: action.payload.data,
				},
				metaData: action.payload.metaData,
				loading: false,
			}),

			GET_TOPICS_LIST_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOPICS_LIST_BY_ID_FULFILLED: (state, action) => ({
				...state,
				topicsData: action.payload,
				loading: false,
			}),

			GET_TOPICS_CATEGORY_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOPICS_CATEGORY_FULFILLED: (state, action) => ({
				...state,
				topicsCategory: action.payload,
				loading: false,
			}),

			GET_TOPICS_FOR_HOME_PAGE_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOPICS_FOR_HOME_PAGE_FULFILLED: (state, action) => ({
				...state,
				topicsListForHomePage: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const topicsActionsMap = {
	getAllTopicsList,
	getTopicsListByCategory,
	getTopicsListByID,
	getTopicsCategory,
	getTopicsForHomePage,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.topics.loading,
	(state: GlobalState) => state.topics.topicsList,
	(state: GlobalState) => state.topics.allTopicsList,
	(state: GlobalState) => state.topics.topicsData,
	(state: GlobalState) => state.topics.topicsCategory,
	(state: GlobalState) => state.topics.topicsListForHomePage,
	(state: GlobalState) => state.topics.metaData,
	(
		loading,
		topicsList,
		allTopicsList,
		topicsData,
		topicsCategory,
		topicsListForHomePage,
		metaData,
	) => ({
		loading,
		topicsList,
		allTopicsList,
		topicsData,
		topicsCategory,
		topicsListForHomePage,
		metaData,
	}),
);

type TopicsSelector = ReturnType<typeof mapHooksToState>;
type TopicsActionsMap = typeof topicsActionsMap;

export const useTopics = () =>
	useRedux<TopicsSelector, TopicsActionsMap>(mapHooksToState, topicsActionsMap);
