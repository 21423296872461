import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import storage from 'util/storage';
import { api } from 'util/api';
import { isExist } from 'util/helper';
import {
	V1AuthLineGetTokenCreateRequestPayload,
	V1AuthLineLinkCreateRequestPayload,
	V1AuthLineVerifyCreateRequestPayload,
	V1AuthLoginCreateRequestPayload,
	V1AuthRegisterCreateRequestPayload,
	V1AuthResetPasswordCreateRequestPayload,
	V1AuthSendUserResetPasswordSmsCreateRequestPayload,
	V1AuthSendUserVerificationSmsCreateRequestPayload,
	V1AuthSocialiteLinkGoogleCreateRequestPayload,
	V1AuthSocialiteLoginGoogleCreateRequestPayload,
	V1AuthSocialiteRegisterGoogleCreateRequestPayload,
	V1AuthVerifyPhoneCodeCreateRequestPayload
} from 'util/api/swaggerApi/data-contracts';

import { getUserInfo, submitUpdateUserInfo } from 'models/member';

import { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import history from 'store/history';

import { GetState, State as GlobalState } from './reducers';
import { MODAL_CATEGORY, openModal, TOAST_TYPE } from './modal';

export const updateAuthForm = createAction(
	'UPDATE_AUTH_FORM',
	({ type, key, data, error }: FormPayload) => ({
		type,
		key,
		data,
		error,
	}),
);

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', (newToken: string) => {
	storage.setToken(newToken);
	return newToken;
});


/**
 * 會員一般登入
 */
export const signIn = createAction('SIGN_IN', (target: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { auth: { signInForm }} = getState();

	const formData = {
		phone: signInForm.phone.value,
		password: signInForm.password.value,
	};

	try {
		const { v1AuthLoginCreate } = api;
		const { status, data } = await v1AuthLoginCreate(formData as V1AuthLoginCreateRequestPayload);

		if (status === 200 && isExist(data?.data?.token)) {
			dispatch(updateAccessToken(data?.data?.token ?? ''));
			await dispatch(getUserInfo());
			const { member: { userInfo } } = getState();
			const isUserVerify = userInfo?.userVerify === '已驗證';

			if (isUserVerify) {
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }));
				dispatch(clearForm('signInForm'));
				history.push(target);
			} else {
				dispatch(openModal({ category: MODAL_CATEGORY.MODAL, type: TOAST_TYPE.WARNING, data: {
					title: '此手機號碼尚未完成驗證',
					text: '跳轉至手機號碼驗證頁',
					btn: '確認',
					target: 'verify'
				}}));
			}
		}
	} catch (e) {
		const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;

		switch (errorCode) {
			case 'USER_NOT_FOUND':
				dispatch(updateAuthForm({
					type: 'signInForm',
					key: 'password',
					data: { value: signInForm.password.value, error: '帳號或密碼錯誤' },
				}));
				break;
			case 'LOGIN_FAIL':
				dispatch(updateAuthForm({
					type: 'signInForm',
					key: 'password',
					data: { value: signInForm.password.value, error: '帳號或密碼錯誤' },
				}));
				break;
			default:
				break;
		}
	}
});

/**
 * 會員登出
 */
export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch) => {
	const { v1AuthLogoutCreate } = api;
	try {
		v1AuthLogoutCreate();
		storage.removeToken();
		storage.removeItem('socialiteToken')
		storage.removeItem('lineToken')
		storage.removeItem('googleToken')
		dispatch(updateAccessToken(''));
		dispatch(
			openModal({
				category: MODAL_CATEGORY.TOAST,
				type: TOAST_TYPE.SUCCESS,
				data: '登出成功',
			}),
		);
		window.location.reload();
		return null;
	} catch (error) {
		return null;
	}
});


/**
 * 會員一般註冊
 */
export const signUp = createAction('SIGN_UP', () => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { auth: { signUpForm } } = getState();

	const DOB = `${signUpForm.birthdayYear.value}-${signUpForm.birthdayMonth.value.padStart(2, '0')}-${signUpForm.birthdayDate.value.padStart(2, '0')}`;

	const formData = {
		name: signUpForm.name.value,
		phone: signUpForm.phone.value,
		email: signUpForm.email.value,
		password: signUpForm.password.value,
		gender: signUpForm.gender.value,
		birthday: DOB,
		city: signUpForm.city.value,
	};

	try {
		const { v1AuthRegisterCreate } = api;
		const { status } = await v1AuthRegisterCreate(formData as V1AuthRegisterCreateRequestPayload);

		if (status === 200) {
			dispatch(clearForm('phoneCodeForm'));
			dispatch(updateAuthForm({
				type: 'phoneCodeForm',
				key: 'phone',
				data: {value: signUpForm.phone.value},
			}));
			dispatch(updateAuthForm({
				type: 'signInForm',
				key: 'password',
				data: {value: signUpForm.password.value},
			}))
			dispatch(clearForm('signUpForm'));
			history.push('/verify');
		}
	} catch (e) {
		const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;

		switch (errorCode) {
			case 'PHONE_EXISTED':
				dispatch(updateAuthForm({
					type: 'signUpForm',
					key: 'phone',
					data: { value: signUpForm.phone.value, error: '請使用其它手機號碼進行註冊' },
				}));
				break;
			default:
				break;
		}
	}
});

/**
 * 發送認證簡訊
 */
export const sendUserVerificationSms = createAction('SEND_USER_VERIFICATION_SMS', (phone: string, resend: boolean) => async (dispatch: Dispatch): Promise<void> => {
	try {
		const { v1AuthSendUserVerificationSmsCreate } = api;
		const { status } = await v1AuthSendUserVerificationSmsCreate({ phone } as V1AuthSendUserVerificationSmsCreateRequestPayload);

		if (status === 200) {
			if (resend) {
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '驗證碼已重新發送' }));
			}
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
		history.push('/signin')
	}
});

/**
 * 發送重設密碼認證簡訊
 */
export const sendUserResetPasswordSmsCreate = createAction('SEND_USER_RESET_PASSWORD_SMS', (phone: string, resend: boolean) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { auth: { phoneCodeForm } } = getState();

	try {
		const { v1AuthSendUserResetPasswordSmsCreate } = api;
		const { status } = await v1AuthSendUserResetPasswordSmsCreate({ phone } as V1AuthSendUserResetPasswordSmsCreateRequestPayload);

		if (status === 200) {
			if (resend) {
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '驗證碼已重新發送' }));
			} else {
				history.push('/verify?isResetPassword=true');
			}
		}
	} catch (e) {
		const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;

		switch (errorCode) {
			case 'USER_NOT_FOUND':
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'phone',
					data: { value: phoneCodeForm.phone.value, error: '帳號錯誤' },
				}));
				break;
			case 'USER_IS_DISABLE':
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'phone',
					data: { value: phoneCodeForm.phone.value, error: '帳號錯誤' },
				}));
				break;
			default:
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'phone',
					data: { value: phoneCodeForm.phone.value, error: '帳號錯誤' },
				}));
				break;
		}
	}
});

/**
 * 發送重設密碼
 */
export const resetPassword = createAction('RESET_PASSWORD', () => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { auth: { signUpForm } } = getState();

	const formData = {
		phone: signUpForm.phone.value,
		password: signUpForm.password.value,
	};

	try {
		const { v1AuthResetPasswordCreate } = api;
		const { status } = await v1AuthResetPasswordCreate(formData as V1AuthResetPasswordCreateRequestPayload);

		if (status === 200) {
			dispatch(clearForm('signUpForm'));
			history.push('/reset-password?isResetSuccess=true');
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
	}
});

/**
 * 驗證簡訊認證碼
 */
export const verifyPhoneCode = createAction('VERIFY_PHONE_CODE', (type: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { auth: { phoneCodeForm },} = getState();

	const formData = {
		phone: phoneCodeForm.phone.value,
		code: phoneCodeForm.code.value,
		use_for: type
	};

	try {
		const { v1AuthVerifyPhoneCodeCreate } = api;
		const { status } = await v1AuthVerifyPhoneCodeCreate(formData as V1AuthVerifyPhoneCodeCreateRequestPayload);

		if (status === 200 && type === 'register') {
			if (storage.getToken()) {
				dispatch(clearForm('signInForm'))
				// 註冊流程 第三方註冊 前面已經拿到token登入
				if (storage.getItem('lineToken') || storage.getItem('googleToken')) {
					dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }));
					history.push('/signup?isSignUpSuccess=true')
				} else {
					// 註冊 /一般註冊已經有token = 登入後驗證 - 導回頁首驗證成功
					dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '手機驗證成功' }));
					history.push('/');
				}
			} else {
				// 註冊流程 驗證成功 尚未登入
				// 註冊 /一般註冊沒有token - 打登入取得token直接登入
				dispatch(updateAuthForm({
					type: 'signInForm',
					key: 'phone',
					data: {value: phoneCodeForm.phone.value},
				}))
				dispatch(signIn('/signup?isSignUpSuccess=true'))
				dispatch(clearForm('phoneCodeForm'));
			}
		} else if (status === 200 && type === 'resetPassword') {
			dispatch(updateAuthForm({
				type: 'signUpForm',
				key: 'phone',
				data: { value: phoneCodeForm.phone.value, error: '' },
			}));
			history.push('/reset-password');
			dispatch(clearForm('phoneCodeForm'));
		}
	} catch (e) {
		const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;

		switch (errorCode) {
			case 'VERIFY_FAIL':
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'code',
					data: { value: phoneCodeForm.code.value, error: '驗證碼錯誤' },
				}));
				break;
			case 'CODE_NOT_FOUND':
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'code',
					data: { value: phoneCodeForm.code.value, error: '驗證碼已失效' },
				}));
				break;
			default:
				dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'code',
					data: { value: phoneCodeForm.code.value, error: '發生錯誤' },
				}));
				break;
		}
	}
});

/**
 * 取得 Line Token
 */
export const getLineAuthToken = createAction('GET_TOKEN', (code: string, type: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	try {
		const { v1AuthLineGetTokenCreate } = api;
		const { status, data } = await v1AuthLineGetTokenCreate({code, type} as V1AuthLineGetTokenCreateRequestPayload);
		const {
			member: { userInfo },
		} = getState();

		if (status === 200) {
			storage.setItem('lineToken', (data as { data: { idToken: string; } })?.data?.idToken as string)
			if (storage.getItem('line-from') === 'member-centre' && isExist(userInfo?.phone)) {
				// 從會員中心來 → 綁定作業
				dispatch(lineLink(userInfo.phone as string))
			} else {
				// 從其他地方來 創建帳號+更新帳號
				dispatch(lineVerify())
			}

		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
		history.push('/signup');
	}
});

/**
 * Line 註冊/登入
 */
export const lineVerify = createAction('LINE_VERIFY', () => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	try {
		const { v1AuthLineVerifyCreate } = api;
		const { status, data } = await v1AuthLineVerifyCreate({id_token: storage.getItem('lineToken')} as V1AuthLineVerifyCreateRequestPayload);

		if (status === 200 && isExist((data as { data: { token: string; }})?.data?.token)) {
			dispatch(updateAccessToken((data as { data: { token: string; }})?.data?.token ?? ''));
			await dispatch(getUserInfo());
			const { member: { userInfo } } = getState();
			const isUserVerify = userInfo?.userVerify === '已驗證';

			if (isUserVerify) {
				// 過去註冊 Line 會員，直接進行 Line 登入
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }));
				history.push('/');
			} else {
				// 過去註冊 Line 會員，但尚未驗證，導進驗證頁
				dispatch(openModal({ category: MODAL_CATEGORY.MODAL, type: TOAST_TYPE.WARNING, data: {
					title: '此手機號碼尚未完成驗證',
					text: '跳轉至手機號碼驗證頁',
					btn: '確認',
					target: 'verify'
				}}));
			}
		} else {
			// 過去未註冊過，進行 Line 註冊
			storage.setItem('socialiteToken', (data as { data: { socialiteToken: string; }})?.data?.socialiteToken as string)
			dispatch(clearForm('signUpForm'));
			dispatch(updateAuthForm({
				type: 'signUpForm',
				key: 'email',
				data: { value: (data as { data: { email: string; }})?.data.email as string, error: '' },
			}))
			dispatch(updateAuthForm({
				type: 'signUpForm',
				key: 'name',
				data: { value: (data as { data: { name: string; }})?.data.name as string, error: '' },
			}))
			history.push('/social-signup?from=line');
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
	}
});

/**
 * 會員綁定 Line 帳號
 */
export const lineLink = createAction('LINE_LINK', (phone: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	try {
		const { v1AuthLineLinkCreate } = api;
		const { status, data } = await v1AuthLineLinkCreate({phone, socialite_token: storage.getItem('lineToken')} as V1AuthLineLinkCreateRequestPayload);
		const { auth: { signUpForm } } = getState();

		if (status === 200) {
			if (storage.getItem('line-from') === 'member-centre') {
				storage.removeItem('line-from')
				history.push('/member-centre');
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '綁定成功' }))
			} else if (isExist((data as { data: { token: string; }})?.data?.token)){
				dispatch(updateAccessToken((data as { data: { token: string; }})?.data?.token ?? ''));

				const DOB = `${signUpForm.birthdayYear.value}-${signUpForm.birthdayMonth.value.padStart(2, '0')}-${signUpForm.birthdayDate.value.padStart(2, '0')}`;
				dispatch(submitUpdateUserInfo({ key: 'birthday', value: DOB }, true));
				dispatch(submitUpdateUserInfo({ key: 'email', value: signUpForm.email.value }, true));
				dispatch(submitUpdateUserInfo({ key: 'name', value: signUpForm.name.value }, true));
				dispatch(submitUpdateUserInfo({ key: 'gender', value: signUpForm.gender.value }, true));
				dispatch(submitUpdateUserInfo({ key: 'city', value: signUpForm.city.value }, true));

				dispatch(clearForm('phoneCodeForm'));
				dispatch(dispatch(updateAuthForm({
					type: 'phoneCodeForm',
					key: 'phone',
					data: {value: signUpForm.phone.value},
				})));
				history.push('/verify');
			}
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
		history.push('/member-centre');
	}
});

/**
 * 第三方 Google 登入 (註冊時，也先 call 此 api 判斷該第三方帳號是否存在)
 */
export const loginGoogle = createAction('LOGIN_GOOGLE', (token : string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	try {
		const { v1AuthSocialiteLoginGoogleCreate } = api;
		const { status, data } = await v1AuthSocialiteLoginGoogleCreate({token} as V1AuthSocialiteLoginGoogleCreateRequestPayload);

		if (status === 200 && isExist((data as { data: { token: string; }})?.data?.token)) {
			dispatch(updateAccessToken((data as { data: { token: string; }})?.data?.token ?? ''));
			await dispatch(getUserInfo());
			const { member: { userInfo } } = getState();
			const isUserVerify = userInfo?.userVerify === '已驗證';

			if (isUserVerify) {
				// 過去註冊 google 會員，直接進行 google 登入
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }));
				history.push('/');
			} else {
				// 過去註冊 google 會員，但尚未驗證，導進驗證頁
				dispatch(openModal({ category: MODAL_CATEGORY.MODAL, type: TOAST_TYPE.WARNING, data: {
					title: '此手機號碼尚未完成驗證',
					text: '跳轉至手機號碼驗證頁',
					btn: '確認',
					target: 'verify'
				}}));
			}
		} else if (isExist((data as { data: { email: string; }})?.data?.email)) {
			// 以此 google token 找不到會員資料，導進 google 註冊
			storage.setItem('googleToken', token)
			dispatch(clearForm('signUpForm'));
			dispatch(updateAuthForm({
				type: 'signUpForm',
				key: 'email',
				data: { value: (data as { data: { email: string; }})?.data.email as string, error: '' },
			}))
			dispatch(updateAuthForm({
				type: 'signUpForm',
				key: 'name',
				data: { value: (data as { data: { name: string; }})?.data.name as string, error: '' },
			}))
			history.push('/social-signup?from=google');
		}
	} catch (e) {
		const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;

		switch (errorCode) {
			default:
				dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
				break;
		}
	}
});

/**
 * 會員註冊輸入 phone 後，綁定 Google 帳號
 */
export const registerGoogle = createAction('REGISTER_GOOGLE', (phone: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	try {
		const { v1AuthSocialiteRegisterGoogleCreate } = api;
		const { status, data } = await v1AuthSocialiteRegisterGoogleCreate({phone, token: storage.getItem('googleToken')} as V1AuthSocialiteRegisterGoogleCreateRequestPayload);
		const { auth: { signUpForm } } = getState();

		if (status === 200 && isExist((data as { data: { token: string; }})?.data?.token)) {
			dispatch(updateAccessToken((data as { data: { token: string; }})?.data?.token ?? ''));

			const DOB = `${signUpForm.birthdayYear.value}-${signUpForm.birthdayMonth.value.padStart(2, '0')}-${signUpForm.birthdayDate.value.padStart(2, '0')}`;
			dispatch(submitUpdateUserInfo({ key: 'birthday', value: DOB }, true));
			dispatch(submitUpdateUserInfo({ key: 'email', value: signUpForm.email.value }, true));
			dispatch(submitUpdateUserInfo({ key: 'name', value: signUpForm.name.value }, true));
			dispatch(submitUpdateUserInfo({ key: 'gender', value: signUpForm.gender.value }, true));
			dispatch(submitUpdateUserInfo({ key: 'city', value: signUpForm.city.value }, true));

			dispatch(clearForm('phoneCodeForm'));
			dispatch(dispatch(updateAuthForm({
				type: 'phoneCodeForm',
				key: 'phone',
				data: {value: signUpForm.phone.value},
			})));
			history.push('/verify');
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
	}
});

/**
 * 主帳號已存在，連結 google 帳號
 */
export const linkGoogle = createAction('LINK_GOOGLE', (token: string) => async (dispatch: Dispatch): Promise<void> => {
	try {
		const { v1AuthSocialiteLinkGoogleCreate } = api;
		const { status } = await v1AuthSocialiteLinkGoogleCreate({token} as V1AuthSocialiteLinkGoogleCreateRequestPayload);

		if (status === 200) {
			dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '綁定成功' }))
			dispatch(getUserInfo())
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
	}
});

export const clearForm = createAction('CLEAR_FORM', (type: string) => {
	let clearData;

	switch (type) {
		case 'signInForm':
			clearData = defaultSignInForm;
			break;
		case 'signUpForm':
			clearData = defaultSignUpForm;
			break;
		case 'phoneCodeForm':
			clearData = defaultPhoneCodeForm;
			break;
		default:
			break;
	}

	return {
		type,
		data: clearData,
	};
});

export const isGoogleLibraryLoaded = createAction('IS_GOOGLE_LIBRARY_LOADED', (isLoaded: any) => isLoaded);
export interface FormItem {
	value: HTMLInputElement['value'] | boolean;
	error?: string;
}

export interface FormPayload {
	type?: string;
	key: string;
	value?: HTMLInputElement['value'];
	data: FormItem;
	error?: string;
}

export interface FormItemProperty {
	value: HTMLInputElement['value'];
	error: string;
}

export const defaultSignInForm = {
	phone: { value: '', error: '' },
	password: { value: '', error: '' },
};

export const defaultSignUpForm = {
	name: { value: '', error: '' },
	phone: { value: '', error: '' },
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	passwordCheck: { value: '', error: '' },
	gender: { value: '', error: '' },
	birthdayYear: { value: '', error: '' },
	birthdayMonth: { value: '', error: '' },
	birthdayDate: { value: '', error: '' },
	city: { value: '', error: '' },
};

export const defaultPhoneCodeForm = {
	phone: { value: '', error: '' },
	code: { value: '', error: '' },
};

export interface signInFormProperty {
	phone: FormItemProperty;
	password: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface signUpFormProperty {
	name: FormItemProperty;
	phone: FormItemProperty;
	email: FormItemProperty;
	password: FormItemProperty;
	passwordCheck: FormItemProperty;
	gender: FormItemProperty;
	birthdayYear: FormItemProperty;
	birthdayMonth: FormItemProperty;
	birthdayDate: FormItemProperty;
	city: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface phoneFormCodeProperty {
	phone: FormItemProperty;
	code: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface State {
	token: string | null;
	signInForm: signInFormProperty;
	signUpForm: signUpFormProperty;
	phoneCodeForm: phoneFormCodeProperty;
	[key: string]: any;
}

export const defaultState: State = {
	token: null,
	signInForm: defaultSignInForm,
	signUpForm: defaultSignUpForm,
	phoneCodeForm: defaultPhoneCodeForm,
	isGoogleLibraryLoad: false,
};

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			UPDATE_AUTH_FORM: (state, action) => ({
				...state,
				[(action.payload.type as string)]: {
					...state[action.payload.type as string],
					[action.payload.key]: {
						...state[action.payload.type as string][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			UPDATE_ACCESS_TOKEN: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			IS_GOOGLE_LIBRARY_LOADED: (state, action) => ({
				...state,
				isGoogleLibraryLoad: action.payload,
			}),

			CLEAR_FORM: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),
		},
		defaultState,
	),
};

const selectAuth = createSelector(
	(state: GlobalState) => state.auth.token,
	(state: GlobalState) => state.auth.signInForm,
	(state: GlobalState) => state.auth.signUpForm,
	(state: GlobalState) => state.auth.phoneCodeForm,
	(state: GlobalState) => state.auth.isGoogleLibraryLoad,
	(token, signInForm, signUpForm, phoneCodeForm, isGoogleLibraryLoad) => ({
		token,
		signInForm,
		signUpForm,
		phoneCodeForm,
		isGoogleLibraryLoad
	}),
);

const authActionsMap = {
	updateAuthForm,
	signIn,
	logout,
	signUp,
	sendUserVerificationSms,
	sendUserResetPasswordSmsCreate,
	resetPassword,
	verifyPhoneCode,
	updateAccessToken,
	getLineAuthToken,
	lineVerify,
	lineLink,
	isGoogleLibraryLoaded,
	loginGoogle,
	registerGoogle,
	linkGoogle,
	clearForm
};

export const useAuth = () => useRedux(selectAuth, authActionsMap);
