import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { isExist } from 'util/helper';
import { sendUserVerificationSms, updateAuthForm } from 'models/auth';
import { parseQueryString } from 'util/parseQueryString';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/verify',
	components: () => [import('./component')],
	render: ([Verify]) => <Verify />,
	onEnter: async ({ store, history }) => {
		const {
			auth: {
				phoneCodeForm
			},
			member: {
				userInfo
			}
		} = store.getState();
		const { isResetPassword } = parseQueryString();

		// 手機驗證的前提是已經做過手機驗證，所以沒有 phoneCodeForm.phone.value 的情況下，直接導回首頁
		if (isExist(phoneCodeForm.phone.value)) {
			store.dispatch(updateAuthForm({
				type: 'phoneCodeForm',
				key: 'code',
				data: { value: '', error: '' },
			}));

			if (!isResetPassword) {
				store.dispatch(sendUserVerificationSms(phoneCodeForm.phone.value, false));
			}
		} else if (isExist(userInfo?.phone)) {
			store.dispatch(updateAuthForm({
				type: 'phoneCodeForm',
				key: 'phone',
				data: { value: userInfo?.phone ?? '', error: '' },
			}));
			store.dispatch(updateAuthForm({
				type: 'phoneCodeForm',
				key: 'code',
				data: { value: '', error: '' },
			}));
		} else {
			history.push('/');
		}
	},
};

export default routes;
