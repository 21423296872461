import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/event';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/hook/useLockScroll';
import { isExist } from 'util/helper';

import { useGlobal } from 'models/global';
import { useAuth } from 'models/auth';
import { useMember } from 'models/member';

import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import Navigation from 'components/molecules/Navigation';
import Search from 'components/molecules/Search';
import ButtonLogin from 'components/molecules/ButtonLogin';

import Logo from 'images/ci/logo.inline.svg';
import IconMenu from 'images/icon/menu.inline.svg';
import IconClose from 'images/icon/close.inline.svg';
import IconUser from 'images/icon/user.inline.svg';

import styles from './index.module.css';

const Header: React.FC = () => {
	const media = useMedia();
	const [
		{ isSearchBarOpen, isNavMenuOpen, isUserMenuOpen },
		{ toggleNavOpen, setNavClose, setUserMenuOpen, setUserMenuClose },
	] = useGlobal();
	const [, { logout }] = useAuth();
	const [{ userInfo }] = useMember();
	const isLogin = isExist(userInfo?.id);

	useEffect(() => {
		if (isNavMenuOpen || isSearchBarOpen) {
			fixBackgroundScrollY();
		} else {
			unfixBackgroundScrollY();
		}

		if (media === 'desktop') {
			unfixBackgroundScrollY();
		}

		return () => {
			unfixBackgroundScrollY();
		};
	}, [isNavMenuOpen, isSearchBarOpen, media]);

	useEffect(() => {
		if (isSearchBarOpen) {
			setNavClose();
		}
	}, [isSearchBarOpen]);

	return (
		<header
			className={classnames(styles.header, {
				[styles.navActive]: isNavMenuOpen || isSearchBarOpen,
			})}
		>
			<Link className={styles.logo} to="/">
				<Logo />
			</Link>
			<Navigation isMenuOpen={isNavMenuOpen} />
			<div className={styles.buttonGroup}>
				<Search />
				{!isLogin && media === 'desktop' && <ButtonLogin />}
				{media !== 'desktop' && (
					<Icon
						className={styles.buttonMenu}
						src={isNavMenuOpen ? IconClose : IconMenu}
						onClick={toggleNavOpen}
					/>
				)}
				{isLogin && (
					<div
						className={styles.userMenuContainer}
						onMouseEnter={() => {
							setUserMenuOpen();
						}}
						onMouseLeave={() => {
							setUserMenuClose();
						}}
					>
						<Icon className={styles.buttonUser} src={IconUser} />
						{isUserMenuOpen && (
							<div className={styles.userMenuWrapper}>
								<ul className={styles.userMenuList}>
									<li className={styles.userMenuItem} >
										<Link
											to="/member-centre"
											onClick={() => {
												setUserMenuClose();
											}}>
											會員中心
										</Link>
									</li>
									<li className={styles.userMenuItem}>
										<button
											type="button"
											onClick={() => {
												logout();
											}}
										>
											登出
										</button>
									</li>
								</ul>
							</div>
						)}
					</div>
				)}
			</div>
			{media !== 'desktop' && (
				<div
					className={classnames(styles.navBackground, { [styles.active]: isNavMenuOpen })}
					onClick={toggleNavOpen}
					role="presentation"
				/>
			)}
		</header>
	);
};

export default Header;
