import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import { isExist } from 'util/helper';

import Icon from 'components/atoms/Icon';

import styles from './index.module.css';

export interface ButtonProperty {
	className?: string;
	disabled?: boolean;
	outline?: boolean;
	underline?: boolean;
	color?: 'primary' | 'secondary';
	size?: 'large' | 'normal' | 'small' | 'x-small'; // height: 48px, 44px, 40px, 36px
	fullWidth?: boolean;
	Icon?: string;
	children: React.ReactNode;
	onClick?: (e: MouseEvent) => void;
}

const Button: React.FC<ButtonProperty> = ({
	className,
	disabled = false,
	outline = false,
	underline = false,
	size = 'normal',
	color = 'primary',
	fullWidth = false,
	Icon: PropsIcon,
	children,
	onClick = () => {},
	...props
}) => (
	<button
		className={classnames(
			styles.button,
			className,
			{
				[styles.disabled]: disabled,
				[styles.filled]: !outline && !underline,
				[styles.outline]: outline && !underline,
				[styles.underline]: underline,
				[styles.fullWidth]: fullWidth,
				[styles.hasIcon]: isExist(PropsIcon),
			},
			styles[`size-${size}`],
			styles[`color-${color}`],
		)}
		type="button"
		onClick={e => {
			if (!disabled) {
				onClick(e);
			}
		}}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<span>{children}</span>
		{PropsIcon && <Icon className={styles.icon} src={PropsIcon} />}
	</button>
);

export default Button;
