import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	V1TagListRequestParams,
	NewsAndBlogIndexResource,
} from 'util/api/swaggerApi/data-contracts';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export interface MetaDataProperty {
	currentPage?: number;
	from?: number;
	lastPage?: number;
	path?: string;
	perPage?: number;
	to?: number;
	total?: number;
}

/**
 * 依標籤取得所有文章
 */
export const getArticleListByTag = createAction(
	'GET_ARTICLE_LIST_BY_TAG',
	async (params: V1TagListRequestParams) => {
		try {
			const { v1TagList } = api;
			const { data } = await v1TagList(params);
			return { data: data?.data, tagName: data?.tag?.name, metaData: data?.meta };
		} catch (e) {
			return [];
		}
	},
);

export interface State {
	loading: boolean;
	tagName: string;
	tagArticleList: NewsAndBlogIndexResource[];
	metaData: MetaDataProperty;
}

export const defaultState: State = {
	loading: false,
	tagName: '',
	tagArticleList: [],
	metaData: {},
};

export const reducer = {
	tag: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ARTICLE_LIST_BY_TAG_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ARTICLE_LIST_BY_TAG_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				tagName: action.payload.tagName,
				tagArticleList: action.payload.data,
				metaData: action.payload.metaData,
			}),
		},
		defaultState,
	),
};

const tagActionsMap = {
	getArticleListByTag,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.tag.loading,
	(state: GlobalState) => state.tag.tagName,
	(state: GlobalState) => state.tag.tagArticleList,
	(state: GlobalState) => state.tag.metaData,
	(loading, tagName, tagArticleList, metaData) => ({
		loading,
		tagName,
		tagArticleList,
		metaData,
	}),
);

type TagSelector = ReturnType<typeof mapHooksToState>;
type TagActionsMap = typeof tagActionsMap;

export const useTag = () => useRedux<TagSelector, TagActionsMap>(mapHooksToState, tagActionsMap);
