import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/copyright-notice',
	components: () => [import('./component')],
	render: ([CopyrightNotice]) => <CopyrightNotice />,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
};
export default routes;
