import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

const setNavOpen = createAction('SET_NAV_OPEN');
const setNavClose = createAction('SET_NAV_CLOSE');
const toggleNavOpen = createAction('TOGGLE_NAV_OPEN');
const setSearchBarOpen = createAction('SET_SEARCH_BAR_OPEN');
const setSearchBarClose = createAction('SET_SEARCH_BAR_CLOSE');
const toggleSearchBarOpen = createAction('TOGGLE_SEARCH_BAR_OPEN');
const setUserMenuOpen = createAction('SET_USER_MENU_OPEN');
const setUserMenuClose = createAction('SET_USER_MENU_CLOSE');
const toggleUserMenuOpen = createAction('TOGGLE_USER_MENU_OPEN');

export interface State {
	isNavMenuOpen: boolean;
	isSearchBarOpen: boolean;
	isUserMenuOpen: boolean;
}

export const defaultState: State = {
	isNavMenuOpen: false,
	isSearchBarOpen: false,
	isUserMenuOpen: false,
};

export const reducer = {
	global: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_NAV_OPEN: state => ({
				...state,
				isNavMenuOpen: true,
			}),

			SET_NAV_CLOSE: state => ({
				...state,
				isNavMenuOpen: false,
			}),

			TOGGLE_NAV_OPEN: state => ({
				...state,
				isNavMenuOpen: !state.isNavMenuOpen,
			}),

			SET_SEARCH_BAR_OPEN: state => ({
				...state,
				isSearchBarOpen: true,
			}),

			SET_SEARCH_BAR_CLOSE: state => ({
				...state,
				isSearchBarOpen: false,
			}),

			TOGGLE_SEARCH_BAR_OPEN: state => ({
				...state,
				isSearchBarOpen: !state.isSearchBarOpen,
			}),

			SET_USER_MENU_OPEN: state => ({
				...state,
				isUserMenuOpen: true,
			}),

			SET_USER_MENU_CLOSE: state => ({
				...state,
				isUserMenuOpen: false,
			}),

			TOGGLE_USER_MENU_OPEN: state => ({
				...state,
				isUserMenuOpen: !state.isUserMenuOpen,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => state.global;

const globalActionsMap = {
	setNavOpen,
	toggleNavOpen,
	setNavClose,
	setSearchBarOpen,
	setSearchBarClose,
	toggleSearchBarOpen,
	setUserMenuOpen,
	setUserMenuClose,
	toggleUserMenuOpen,
};

type GlobalSelector = ReturnType<typeof mapHooksToState>;
type GlobalActionsMap = typeof globalActionsMap;

export const useGlobal = () =>
	useRedux<GlobalSelector, GlobalActionsMap>(mapHooksToState, globalActionsMap);
