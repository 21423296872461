import React, { useEffect } from 'react';
import classnames from 'classnames';

import { MODAL_CATEGORY, useModal } from 'models/modal';


import { useBoolean } from 'util/hook';
import { isExist } from 'util/helper';

import Icon from 'components/atoms/Icon';

import SuccessIcon from 'images/icon/toast-success.inline.svg';
import WarningIcon from 'images/icon/toast-warning.inline.svg';
import ErrorIcon from 'images/icon/toast-error.inline.svg';

import { useHistory } from 'models/routing';
import { useAuth } from 'models/auth';
import { useMember } from 'models/member';
import styles from './index.module.css';
import Button from '../Button';

const Toast: React.FC = () => {
	const [isShowToast, { setTrue: showToast, setFalse: hideToast }] = useBoolean({
		defaultBoolean: false,
	});
	const [
		{
			modal: {
				toast: { type, data },
				modal: { type: modalType, data: modalData },
			},
		},
		{ closeModal },
	] = useModal();
	const history = useHistory();
	const [{ userInfo }] = useMember();
	const [, { updateAuthForm }] = useAuth();

	useEffect(() => {
		if (isExist(type)) {
			showToast();
			// setTimeout(() => hideToast(), 3000);
			setTimeout(() => closeModal(MODAL_CATEGORY.TOAST), 3500);
		}
	}, [type]);

	useEffect(() => {
		if (isExist(modalType)) {
			showToast();
		}
	}, [modalType]);

	const closeModalHandler = () => {
		if (modalData?.target === 'verify') {
			updateAuthForm({
				type: 'phoneCodeForm',
				key: 'phone',
				data: { value: userInfo.phone, error: '' }
			})
			history.replace('/verify')
		}
		// hideToast();
		closeModal(MODAL_CATEGORY.MODAL)
	}

	return (
		<div
			className={classnames(styles.toast, {
				[styles.open]: isShowToast,
				[styles.close]: !isShowToast,
			})}
		>
			{isExist(type) && <div className={classnames(styles.toastContainer, styles[type])}>
				{type === 'success' && <Icon src={SuccessIcon} size='large'/>}
				{type === 'warning' && <Icon src={WarningIcon} size='large'/>}
				{type === 'error' && <Icon src={ErrorIcon} size='large'/>}
				<div className={styles.text}>{data}</div>
			</div>}

			{isExist(modalType) && <div className={classnames(styles.modalContainer, styles[modalType])}>
				<div className={styles.modalInfo}>
					{modalType === 'warning' && <Icon src={WarningIcon} size='large' style={{ alignItems: 'flex-start' }}/>}
					{modalType === 'error' && <Icon src={ErrorIcon} size='large' style={{ alignItems: 'flex-start' }}/>}
					<div>
						<div className={styles.infoTitle}>{modalData?.title}</div>
						<div>{modalData?.text}</div>
					</div>
				</div>
				<Button
					className={styles.button}
					size='x-small'
					onClick={closeModalHandler}
				>
					{modalData?.btn}
				</Button>

			</div>}
		</div>
	);
};

export default Toast;
