import { updateAuthForm } from 'models/auth';
import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/forget-password',
	components: () => [import('./component')],
	render: ([ForgetPassword]) => <ForgetPassword />,
	onEnter: async ({ store }) => {
		store.dispatch(
			updateAuthForm({
				type: 'phoneCodeForm',
				key: 'phone',
				data: { value: '', error: '' },
			})
		)
	},
};
export default routes;
