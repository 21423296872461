import React from 'react';
import { useCookies } from 'react-cookie';
import Link from '../Link';

import styles from './index.module.css';

const CookieCard: React.FC = () => {
	const [_, setCookie] = useCookies(["cookieConsent"])
	const date = new Date();
	const expiryDate = new Date(date.setMonth(date.getMonth() + 5))
	const giveCookieConsent = () => {
		setCookie("cookieConsent", true, { path: "/", expires: expiryDate });
	}
	return (
		<div className={styles.cookie}>
			<p>
				我們紀錄 Cookies 資訊，以提供客製化內容及優化使用者體驗，若繼續閱覽本網站內容，即表示您同意我們使用 Cookies。更多關於隱私保護資訊，請閱覽我們的
				<Link to='/privacy-policy' className={styles.link}>隱私權聲明</Link>。
			</p>
			<button className={styles.btn} onClick={giveCookieConsent} type='button'>我接受</button>
		</div>
	)
};

export default CookieCard;