import { combineReducers } from 'redux';

import * as routing from './routing';
import * as member from './member';
import * as blogs from './blog';
import * as banners from './banner';
import * as news from './news';
import * as topics from './topics';
import * as tag from './tag';
import * as global from './global';
import * as modal from './modal';
import * as auth from './auth';

// For Global State interface
export interface State {
	blogs: blogs.State;
	banners: banners.State;
	member: member.State;
	routing: routing.State;
	news: news.State;
	topics: topics.State;
	tag: tag.State;
	global: global.State;
	modal: modal.State;
	auth: auth.State;
}

export type GetState = () => State;

export const defaultState: State = {
	blogs: blogs.defaultState,
	banners: banners.defaultState,
	member: member.defaultState,
	routing: routing.defaultState,
	news: news.defaultState,
	topics: topics.defaultState,
	tag: tag.defaultState,
	global: global.defaultState,
	modal: modal.defaultState,
	auth: auth.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...member.reducer,
	...blogs.reducer,
	...banners.reducer,
	...news.reducer,
	...topics.reducer,
	...tag.reducer,
	...global.reducer,
	...modal.reducer,
	...auth.reducer,
});

export default reducers;
