import React from 'react';
import { isExist } from 'util/helper';
import { CustomRoute } from 'util/hook/useRouter';
import storage from 'util/storage';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/social-signup',
	components: () => [import('./component')],
	render: ([SocialSignUp]) => <SocialSignUp />,
	onEnter: async ({ history }) => {
		const token = storage.getToken();

		if (isExist(token)) {
			history.replace('/member-centre');
		}
	},
};
export default routes;
