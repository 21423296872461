import React, { SyntheticEvent, CSSProperties } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';

import styles from './index.module.css';

// large:24px; normal: 20px; small: 16px;
export type IconSize = 'large' | 'normal' | 'small';

interface IconProperty {
	className?: string;
	src: string;
	size?: IconSize;
	style?: CSSProperties;
	onClick?: (event: SyntheticEvent) => void;
	onMouseEnter?: (event: SyntheticEvent) => void;
	onMouseLeave?: (event: SyntheticEvent) => void;
}

const Icon: React.FC<IconProperty> = ({
	className,
	src: Svg,
	size = 'normal',
	style,
	onClick,
	onMouseEnter = () => {},
	onMouseLeave = () => {},
}) => (
	<div
		className={classnames(
			styles.icon,
			isExist(size) && styles[size],
			{
				[styles.clickable]: isExist(onClick),
			},
			className,
		)}
		style={style}
		role="button"
		tabIndex={0}
		onClick={onClick}
		onKeyPress={() => {}}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		aria-label="botton"
	>
		<Svg />
	</div>
);

export default Icon;
