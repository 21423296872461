
import { isGoogleLibraryLoaded } from 'models/auth';
import { Store } from 'redux';

const insertScript = (id: string, src: string, options: { async: boolean, defer: boolean }, onLoad: any) => {
	if (document.getElementById(id)) {
		return 1;
	}

	const jss = document.getElementsByTagName('script')[0];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (options.async) {
		js.async = true;
	}

	if (options.defer) {
		js.defer = true;
	}
	js.onerror = errorEvent => {
		console.log('insert Script onerror ===>', errorEvent);
	};

	if (onLoad) {
		js.onload = onLoad;
	}

	jss.parentNode.insertBefore(js, jss);
	return 0;
};

export const loadGoogleLibrary = (store: Store) => {
	insertScript(
		'google-client-script',
		'//accounts.google.com/gsi/client',
		{ async: true, defer: true },
		() => {
			store.dispatch(isGoogleLibraryLoaded(true));
		},
	);
};

