import React from 'react';

import CookieCard from 'components/atoms/CookieCard';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import SearchModal from 'components/molecules/SearchModal';
import Toast from 'components/atoms/Toast';
import { useCookies } from 'react-cookie';

import styles from './index.css';

interface AppProperty {
	children: React.ReactNode;
}

const App: React.FC<AppProperty> = ({ children }) => {
	const [cookies] = useCookies(["cookieConsent"]);
	return (
		<>
			<Toast />
			<Header />
			<SearchModal />
			<div className={styles.content}>{children}</div>
			{!cookies.cookieConsent && <CookieCard />}
			<Footer />
		</>
	);
};

export default App;
