import React from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/event';

import { useAuth } from 'models/auth';

import Link from 'components/atoms/Link';
import ButtonLogin from 'components/molecules/ButtonLogin';

import { useMember } from 'models/member';
import { isExist } from 'util/helper';
import styles from './index.module.css';

interface NavigatorProperty {
	isMenuOpen: boolean;
}

const Navigator: React.FC<NavigatorProperty> = ({ isMenuOpen }) => {
	const media = useMedia();
	const [{ userInfo }] = useMember();
	const isLogin = isExist(userInfo?.id);

	const NavData = [
		{
			id: 1,
			name: '最新消息',
			path: '/news',
		},
		{
			id: 2,
			name: '編輯精選',
			path: '/topics',
		},
	];

	if (media !== 'desktop') {
		return (
			<nav className={classnames(styles.navMobile, { [styles.active]: isMenuOpen })}>
				<ul>
					{NavData.map(nav => (
						<li key={nav.id} className={styles.navItem}>
							<Link to={nav.path}>{nav.name}</Link>
						</li>
					))}
				</ul>
				{!isLogin && <ButtonLogin fullWidth />}
			</nav>
		);
	}

	return (
		<nav className={styles.navDesktop}>
			<ul>
				{NavData.map(nav => (
					<li key={nav.id} className={styles.navItem}>
						<Link to={nav.path}>{nav.name}</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Navigator;
