import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getNewsListByID } from 'models/news';

const routes: CustomRoute = {
	path: '/:newsId',
	components: () => [import(/* webpackChunkName: 'newsDetail' */ './component')],
	render: ([NewsDetail]) => <NewsDetail />,
	onEnter: async ({ next, store, params }) => {
		const { newsId } = params;
		const id = parseInt(newsId as string, 10);

		if (!Number.isNaN(id)) {
			store.dispatch(getNewsListByID(id));
		}

		const children = await next();
		return children;
	},
};
export default routes;
