import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/about',
	components: () => [import('./component')],
	render: ([About]) => <About />,
	onEnter: async () => {},
};

export default routes;
