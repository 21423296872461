import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getArticleListByTag } from 'models/tag';

const routes: CustomRoute = {
	path: '/tags/:tagId',
	components: () => [import('./component')],
	render: ([Tags]) => <Tags />,
	onEnter: async ({ next, store, params }) => {
		const children = await next();
		const { tagId } = params;
		store.dispatch(getArticleListByTag({ id: Number(tagId), page: 1, count: 6 }));
		return children;
	},
};
export default routes;
