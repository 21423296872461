import { clearForm } from 'models/auth';
import React from 'react';
import { isExist } from 'util/helper';
import { CustomRoute } from 'util/hook/useRouter';
import { parseQueryString } from 'util/parseQueryString';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/signup',
	components: () => [import('./component')],
	render: ([Signup]) => <Signup />,
	onEnter: async ({ history, store }) => {
		const { member: { userInfo } } = store.getState();
		const { isSignUpSuccess } = parseQueryString();

		store.dispatch(clearForm('signUpForm'))
		if (isExist(userInfo?.id) && !isSignUpSuccess) {
			history.replace('/member-centre');
		}
	},
};
export default routes;
