import React from 'react';
import qs from 'qs';

import { CustomRoute } from 'util/hook/useRouter';
import storage from 'util/storage';
import { isExist } from 'util/helper';

import { initUserForm, getUserInfo } from 'models/member';
import { MODAL_CATEGORY, openModal, TOAST_TYPE } from 'models/modal';

const routes: CustomRoute = {
	path: '/member-centre',
	components: () => [import('./component')],
	render: ([MemberCentre]) => <MemberCentre />,
	onEnter: async ({ store, history }) => {
		const token = storage.getToken();
		store.dispatch(initUserForm());

		if (isExist(token)) {
			await store.dispatch(getUserInfo());
			const { member: { userInfo } } = store.getState();
			const isUserVerify = userInfo?.userVerify === '已驗證';
			if (!isUserVerify) {
				store.dispatch(openModal({ category: MODAL_CATEGORY.MODAL, type: TOAST_TYPE.WARNING, data: {
					title: '此手機號碼尚未完成驗證',
					text: '跳轉至手機號碼驗證頁',
					btn: '確認',
					target: 'verify'
				}}));
			}
		} else {
			history.replace('/');
		}

		const query = qs.parse(history.location.search.split('?')[1]);
		if (query?.['email-verify'] === 'finish') {
			store.dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.SUCCESS,
					data: '電子信箱驗證成功',
				}),
			)
		} else if (query?.['email-verify'] === 'fail') {
			store.dispatch(
				openModal({
					category: MODAL_CATEGORY.TOAST,
					type: TOAST_TYPE.ERROR,
					data: '電子信箱驗證失敗，請重新驗證',
				}),
			)
		}
	},
};

export default routes;
