import React, { MouseEvent } from 'react';

import { useHistory } from 'models/routing';
import { useGlobal } from 'models/global';

import { History } from 'history';

const onClickHandler =
	(
		callback: (e: MouseEvent<HTMLAnchorElement>) => void,
		history: History,
		to: string,
		query: string,
		setNavClose: () => void,
		setSearchBarClose: () => void,
	) =>
		(e: MouseEvent<HTMLAnchorElement>): void => {
			e.preventDefault();

			if (to) {
				history.push(`${to}${query ? `?${query}` : ''}`);
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
				setNavClose();
				setSearchBarClose();
			}

			callback(e);
		};

interface LinkProperty {
	className?: string;
	type?: 'internal' | 'external';
	to?: string;
	query?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	children: React.ReactNode;
}

const Link: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to = '',
	query = '',
	onClick = () => {},
	children,
}) => {
	const history = useHistory();
	const [, { setNavClose, setSearchBarClose }] = useGlobal();

	if (type === 'external') {
		return (
			<a
				className={className}
				href={to}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
				onKeyPress={() => {}}
			>
				{children}
			</a>
		);
	}

	return (
		<a
			className={className}
			href={to}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, to, query, setNavClose, setSearchBarClose)}
			onKeyPress={() => {}}
		>
			{children}
		</a>
	);
};

export default Link;
