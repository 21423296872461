/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AdminUserResource,
	AdvanceSearchable,
	AdvanceSearchRequest,
	ApiResponseTemplate,
	BannerResource,
	BlogCategoriesResource,
	BlogIndexPageResource,
	BlogIndexResource,
	BlogResource,
	BscoreBscoreAdminUserInitAccountCreateRequestPayload,
	BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
	BscoreDestroyAllCreateRequestPayload,
	BscoreExcelImportExampleFileCreateRequestPayload,
	BscoreExcelImportUploadUrlCreateRequestPayload,
	BscoreExportResult,
	BscoreForgetPasswordCreateRequestPayload,
	BscoreGetForgetMailInfoListRequestParams,
	BscoreGetUnlockMailInfoListRequestParams,
	BscoreGetVerifyMailInfoListRequestParams,
	BscoreImportResult,
	BscoreLoginCreateRequestPayload,
	BscoreResetPasswordCreateRequestPayload,
	BscoreResetPasswordFromMailCreateRequestPayload,
	BscoreSettingsUpdateRequestPayload,
	BscoreSubListDestroyAllCreateRequestPayload,
	BscoreSubListExcelImportExampleFileCreateRequestPayload,
	BscoreSubListExcelImportUploadUrlCreateRequestPayload,
	BscoreSubListUpdateListCreateRequestParams,
	BscoreSubListUpdateListCreateRequestPayload,
	BscoreSubListUpdateRowCreateRequestPayload,
	BscoreUnlockAccountCreateRequestPayload,
	BscoreUpdateListCreateRequestParams,
	BscoreUpdateListCreateRequestPayload,
	BscoreUpdateRowCreateRequestPayload,
	BscoreUploadUploadUrlCreateRequestPayload,
	BscoreVerifyAccountCreateRequestPayload,
	ExcelSearchRequest,
	GetDataCreate,
	GetDataUpdate,
	GetListDataPaginator,
	ImportRequest,
	MenuItemResource,
	MenuResource,
	NewsAndBlogIndexResource,
	NewsCategoriesResource,
	NewsIndexResource,
	NewsResource,
	SearchRequest,
	UserResource,
	V1AuthLineGetTokenCreateRequestPayload,
	V1AuthLineLinkCreateRequestPayload,
	V1AuthLineVerifyCreateRequestPayload,
	V1AuthLoginCreateRequestPayload,
	V1AuthRegisterCreateRequestPayload,
	V1AuthResetPasswordCreateRequestPayload,
	V1AuthSendUserResetPasswordSmsCreateRequestPayload,
	V1AuthSendUserVerificationSmsCreateRequestPayload,
	V1AuthSocialiteLinkGoogleCreateRequestPayload,
	V1AuthSocialiteLoginGoogleCreateRequestPayload,
	V1AuthSocialiteRegisterGoogleCreateRequestPayload,
	V1AuthVerifyPhoneCodeCreateRequestPayload,
	V1BlogListRequestParams,
	V1NewsListRequestParams,
	V1TagListRequestParams,
	V1UserUpdateRequestParams,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description 狀態「上架」的分類
	 *
	 * @tags feature_blog
	 * @name V1BlogCategoriesList
	 * @summary 最新消息分類
	 * @request GET:/api/v1/blog/categories
	 */
	v1BlogCategoriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BlogCategoriesResource[];
				};
			},
			any
		>({
			path: `/api/v1/blog/categories`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 呈現於首頁-最新消息指定類別下的內容
	 *
	 * @tags feature_blog
	 * @name V1BlogIndexPageList
	 * @summary 首頁-最新消息
	 * @request GET:/api/v1/blog/indexPage
	 */
	v1BlogIndexPageList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BlogIndexPageResource[];
				};
			},
			any
		>({
			path: `/api/v1/blog/indexPage`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的最新消息
	 *
	 * @tags feature_blog
	 * @name V1BlogList
	 * @summary 最新消息清單頁
	 * @request GET:/api/v1/blog
	 */
	v1BlogList = (query: V1BlogListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BlogIndexResource[];
					links?: {
						/**
						 * 第一頁網址
						 * @example "http://localhost/blog?page=1"
						 */
						first?: string;
						/**
						 * 最後頁網址
						 * @example "http://localhost/blog?page=1"
						 */
						last?: string;
						/**
						 * 前頁網址
						 * @example "null"
						 */
						prev?: string;
						/**
						 * 下頁網址
						 * @example "null"
						 */
						next?: string;
					};
					meta?: {
						/**
						 * 當前頁數
						 * @example 1
						 */
						current_page?: number;
						/**
						 * 當前頁數的起始資料在整體資料中位於第 N 筆
						 * @example 1
						 */
						from?: number;
						/**
						 * 最後一頁頁數
						 * @example 1
						 */
						last_page?: number;
						/**
						 * 查詢網址
						 * @example "http://localhost/blog"
						 */
						path?: string;
						/**
						 * 每頁顯示筆數
						 * @example 6
						 */
						per_page?: number;
						/**
						 * 當前頁面的結束資料在整體資料中位於第 N 筆
						 * @example 6
						 */
						to?: number;
						/**
						 * 整體資料筆數
						 * @example 6
						 */
						total?: number;
						/** 連結 */
						links?: {
							/**
							 * 網址
							 * @example "null"
							 */
							url?: string;
							/**
							 * 標籤
							 * @example "&laquo; Previous"
							 */
							label?: string;
							/**
							 * 是否啟用
							 * @example false
							 */
							active?: boolean;
						}[];
					};
				};
			},
			any
		>({
			path: `/api/v1/blog`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的最新消息
	 *
	 * @tags feature_blog
	 * @name V1BlogDetail
	 * @summary 最新消息內頁
	 * @request GET:/api/v1/blog/{id}
	 */
	v1BlogDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BlogResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example "403" */
					status?: number;
					/** @example "preview key 不合法" */
					message?: string;
					/** @example "PREVIEW_KEY_IS_INVALID" */
					error_code?: string;
			  }
		>({
			path: `/api/v1/blog/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 code 取得 id_token
	 *
	 * @tags Auth
	 * @name V1AuthLineGetTokenCreate
	 * @summary 取得 Line Token
	 * @request POST:/api/v1/auth/line/get-token
	 */
	v1AuthLineGetTokenCreate = (
		data: V1AuthLineGetTokenCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id_token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/line/get-token`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 id_token 取得 Line 個人資訊，若 LineID 已綁定則會直接登入，若未綁定則走綁定流程回傳相關資訊
	 *
	 * @tags Auth
	 * @name V1AuthLineVerifyCreate
	 * @summary Line 註冊/登入
	 * @request POST:/api/v1/auth/line/verify
	 */
	v1AuthLineVerifyCreate = (
		data: V1AuthLineVerifyCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/verify`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSocialiteLoginGoogleCreate
	 * @summary 第三方 Google 登入 (註冊時，也先 call 此 api 判斷該第三方帳號是否存在)
	 * @request POST:/api/v1/auth/socialite/login/google
	 */
	v1AuthSocialiteLoginGoogleCreate = (
		data: V1AuthSocialiteLoginGoogleCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/**
						 * Google 第三方 email
						 * @example "test@25sprout.com"
						 */
						email?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/socialite/login/google`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSocialiteLinkGoogleCreate
	 * @summary 主帳號已存在，連結 google 帳號
	 * @request POST:/api/v1/auth/socialite/link/google
	 * @secure
	 */
	v1AuthSocialiteLinkGoogleCreate = (
		data: V1AuthSocialiteLinkGoogleCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/socialite/link/google`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSocialiteRegisterGoogleCreate
	 * @summary 會員註冊輸入 phone 後，綁定 Google 帳號
	 * @request POST:/api/v1/auth/socialite/register/google
	 */
	v1AuthSocialiteRegisterGoogleCreate = (
		data: V1AuthSocialiteRegisterGoogleCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/socialite/register/google`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 符合標籤 ID 的最新消息與編輯精選內容
	 *
	 * @tags feature_tag
	 * @name V1TagList
	 * @summary 標籤的最新消息與編輯精選整合清單頁
	 * @request GET:/api/v1/tag
	 */
	v1TagList = (query: V1TagListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsAndBlogIndexResource[];
					links?: {
						/**
						 * 第一頁網址
						 * @example "http://localhost/tag?page=1"
						 */
						first?: string;
						/**
						 * 最後頁網址
						 * @example "http://localhost/tag?page=1"
						 */
						last?: string;
						/**
						 * 前頁網址
						 * @example "null"
						 */
						prev?: string;
						/**
						 * 下頁網址
						 * @example "null"
						 */
						next?: string;
					};
					meta?: {
						/**
						 * 當前頁數
						 * @example 1
						 */
						current_page?: number;
						/**
						 * 當前頁數的起始資料在整體資料中位於第 N 筆
						 * @example 1
						 */
						from?: number;
						/**
						 * 最後一頁頁數
						 * @example 1
						 */
						last_page?: number;
						/**
						 * 查詢網址
						 * @example "http://localhost/tag"
						 */
						path?: string;
						/**
						 * 每頁顯示筆數
						 * @example 6
						 */
						per_page?: number;
						/**
						 * 當前頁面的結束資料在整體資料中位於第 N 筆
						 * @example 6
						 */
						to?: number;
						/**
						 * 整體資料筆數
						 * @example 6
						 */
						total?: number;
						/** 連結 */
						links?: {
							/**
							 * 網址
							 * @example "null"
							 */
							url?: string;
							/**
							 * 標籤
							 * @example "&laquo; Previous"
							 */
							label?: string;
							/**
							 * 是否啟用
							 * @example false
							 */
							active?: boolean;
						}[];
					};
					tag?: {
						/**
						 * 標籤名稱
						 * @example "測試標籤"
						 */
						name?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/tag`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRegisterCreate
	 * @summary 會員註冊
	 * @request POST:/api/v1/auth/register
	 */
	v1AuthRegisterCreate = (data: V1AuthRegisterCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						user_id?: number;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/register`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLoginCreate
	 * @summary 會員一般登入
	 * @request POST:/api/v1/auth/login
	 */
	v1AuthLoginCreate = (data: V1AuthLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example "eyJ0e..." */
						token?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						phone?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/login`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRefreshTokenCreate
	 * @summary Refresh Token
	 * @request POST:/api/v1/auth/refresh-token
	 * @secure
	 */
	v1AuthRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLogoutCreate
	 * @summary 登出
	 * @request POST:/api/v1/auth/logout
	 * @secure
	 */
	v1AuthLogoutCreate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/logout`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendUserVerificationSmsCreate
	 * @summary 寄送會員註冊驗證簡訊
	 * @request POST:/api/v1/auth/send-user-verification-sms
	 */
	v1AuthSendUserVerificationSmsCreate = (
		data: V1AuthSendUserVerificationSmsCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						phone?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-user-verification-sms`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendUserResetPasswordSmsCreate
	 * @summary 寄送會員忘記密碼驗證簡訊
	 * @request POST:/api/v1/auth/send-user-reset-password-sms
	 */
	v1AuthSendUserResetPasswordSmsCreate = (
		data: V1AuthSendUserResetPasswordSmsCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						phone?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-user-reset-password-sms`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 用於驗證手機驗證碼，透過 use_for 參數判斷使用情境(註冊/重設密碼)，根據情境不同做不同處理（註冊：會判斷是否已驗證過｜重設密碼：驗證成功後回傳 phone）
	 *
	 * @tags Auth
	 * @name V1AuthVerifyPhoneCodeCreate
	 * @summary 驗證手機驗證碼
	 * @request POST:/api/v1/auth/verify-phone-code
	 */
	v1AuthVerifyPhoneCodeCreate = (
		data: V1AuthVerifyPhoneCodeCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/verify-phone-code`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthResetPasswordCreate
	 * @summary 重設會員密碼
	 * @request POST:/api/v1/auth/reset-password
	 */
	v1AuthResetPasswordCreate = (
		data: V1AuthResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/reset-password`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLineLinkCreate
	 * @summary 會員綁定 Line 帳號
	 * @request POST:/api/v1/auth/line/link
	 */
	v1AuthLineLinkCreate = (data: V1AuthLineLinkCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/link`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendEmailVerificationCreate
	 * @summary 寄送會員信箱驗證信
	 * @request POST:/api/v1/auth/send-email-verification
	 * @secure
	 */
	v1AuthSendEmailVerificationCreate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/send-email-verification`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description email 驗證後導轉至前台會員中心頁面，成功帶參數 email-verify=finish，失敗則 email-verify=fail
	 *
	 * @tags Auth
	 * @name V1AuthVerifyEmailDetail
	 * @summary 驗證會員信箱驗證信
	 * @request GET:/api/v1/auth/verify-email/{id}/{hash}
	 */
	v1AuthVerifyEmailDetail = (id: string, hash: string, params: RequestParams = {}) =>
		this.http.request<any, void>({
			path: `/api/v1/auth/verify-email/${id}/${hash}`,
			method: 'GET',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserList
	 * @summary 取得會員資訊
	 * @request GET:/api/v1/user
	 * @secure
	 */
	v1UserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			any
		>({
			path: `/api/v1/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserUpdate
	 * @summary 更新會員資訊
	 * @request PUT:/api/v1/user
	 * @secure
	 */
	v1UserUpdate = (query: V1UserUpdateRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackList
	 * @summary 【後端內部測試用】LineLogin (只回傳 code 不做解析)
	 * @request GET:/api/v1/test/line-login/callback
	 */
	v1TestLineLoginCallbackList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						code?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/test/line-login/callback`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackVerifyList
	 * @summary 【後端內部測試用】LineLogin 接收 code 解析
	 * @request GET:/api/v1/test/line-login/callback-verify
	 */
	v1TestLineLoginCallbackVerifyList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-login/callback-verify`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Banner 列表。若 Banner 類型為圖像，則 youtube_link, auto_play 為空；若 Banner 類型為 YouTube 影片，則 link, image, image_mobile 為空。無設定手機版 Banner 圖像，則 image_mobile 為空。
	 *
	 * @tags feature_website_banner
	 * @name V1BannerList
	 * @request GET:/api/v1/banner
	 */
	v1BannerList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource[];
				};
			},
			any
		>({
			path: `/api/v1/banner`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得單一 Banner 資訊。
	 *
	 * @tags feature_website_banner
	 * @name V1BannerDetail
	 * @request GET:/api/v1/banner/{id}
	 */
	v1BannerDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource;
				};
			},
			any
		>({
			path: `/api/v1/banner/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」的分類
	 *
	 * @tags feature_news
	 * @name V1NewsCategoriesList
	 * @summary 最新消息分類
	 * @request GET:/api/v1/news/categories
	 */
	v1NewsCategoriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsCategoriesResource[];
				};
			},
			any
		>({
			path: `/api/v1/news/categories`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的最新消息
	 *
	 * @tags feature_news
	 * @name V1NewsList
	 * @summary 最新消息清單頁
	 * @request GET:/api/v1/news
	 */
	v1NewsList = (query: V1NewsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsIndexResource[];
					links?: {
						/**
						 * 第一頁網址
						 * @example "http://localhost/news?page=1"
						 */
						first?: string;
						/**
						 * 最後頁網址
						 * @example "http://localhost/news?page=1"
						 */
						last?: string;
						/**
						 * 前頁網址
						 * @example "null"
						 */
						prev?: string;
						/**
						 * 下頁網址
						 * @example "null"
						 */
						next?: string;
					};
					meta?: {
						/**
						 * 當前頁數
						 * @example 1
						 */
						current_page?: number;
						/**
						 * 當前頁數的起始資料在整體資料中位於第 N 筆
						 * @example 1
						 */
						from?: number;
						/**
						 * 最後一頁頁數
						 * @example 1
						 */
						last_page?: number;
						/**
						 * 查詢網址
						 * @example "http://localhost/news"
						 */
						path?: string;
						/**
						 * 每頁顯示筆數
						 * @example 6
						 */
						per_page?: number;
						/**
						 * 當前頁面的結束資料在整體資料中位於第 N 筆
						 * @example 6
						 */
						to?: number;
						/**
						 * 整體資料筆數
						 * @example 6
						 */
						total?: number;
						/** 連結 */
						links?: {
							/**
							 * 網址
							 * @example "null"
							 */
							url?: string;
							/**
							 * 標籤
							 * @example "&laquo; Previous"
							 */
							label?: string;
							/**
							 * 是否啟用
							 * @example false
							 */
							active?: boolean;
						}[];
					};
				};
			},
			any
		>({
			path: `/api/v1/news`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的最新消息
	 *
	 * @tags feature_news
	 * @name V1NewsDetail
	 * @summary 最新消息內頁
	 * @request GET:/api/v1/news/{id}
	 */
	v1NewsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example "403" */
					status?: number;
					/** @example "preview key 不合法" */
					message?: string;
					/** @example "PREVIEW_KEY_IS_INVALID" */
					error_code?: string;
			  }
		>({
			path: `/api/v1/news/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 後台登入用，會取得 JWT Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreLoginCreate
	 * @request POST:/api/bscore/login
	 */
	bscoreLoginCreate = (data: BscoreLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/** 模組 route 列表，提供跳轉用 */
						default?: string[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/login`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Refresh Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreRefreshTokenCreate
	 * @request POST:/api/bscore/refresh-token
	 * @secure
	 */
	bscoreRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 變更密碼
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreResetPasswordCreate
	 * @request POST:/api/bscore/resetPassword
	 * @secure
	 */
	bscoreResetPasswordCreate = (
		data: BscoreResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/resetPassword`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_admin_user
	 * @name BscoreMeList
	 * @summary 取得目前使用者的資料
	 * @request GET:/api/bscore/me
	 * @secure
	 */
	bscoreMeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 重置使用者，並寄送驗證信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserInitAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/init-account
	 * @secure
	 */
	bscoreBscoreAdminUserInitAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserInitAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/init-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 寄送帳號解鎖信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserUnlockAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/unlock-account
	 * @secure
	 */
	bscoreBscoreAdminUserUnlockAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/unlock-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得帳號解鎖信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetUnlockMailInfoList
	 * @request GET:/api/bscore/get-unlock-mail-info
	 */
	bscoreGetUnlockMailInfoList = (
		query: BscoreGetUnlockMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-unlock-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 解鎖帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreUnlockAccountCreate
	 * @request POST:/api/bscore/unlock-account
	 */
	bscoreUnlockAccountCreate = (
		data: BscoreUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/unlock-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得信箱驗證信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetVerifyMailInfoList
	 * @request GET:/api/bscore/get-verify-mail-info
	 */
	bscoreGetVerifyMailInfoList = (
		query: BscoreGetVerifyMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-verify-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreVerifyAccountCreate
	 * @request POST:/api/bscore/verify-account
	 */
	bscoreVerifyAccountCreate = (
		data: BscoreVerifyAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/verify-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreForgetPasswordCreate
	 * @request POST:/api/bscore/forget-password
	 */
	bscoreForgetPasswordCreate = (
		data: BscoreForgetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						next_can_send?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/forget-password`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼資訊
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetForgetMailInfoList
	 * @request GET:/api/bscore/get-forget-mail-info
	 */
	bscoreGetForgetMailInfoList = (
		query: BscoreGetForgetMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-forget-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼
	 *
	 * @tags bscore_admin_user
	 * @name BscoreResetPasswordFromMailCreate
	 * @request POST:/api/bscore/reset-password-from-mail
	 */
	bscoreResetPasswordFromMailCreate = (
		data: BscoreResetPasswordFromMailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/reset-password-from-mail`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetModuleConfigDetail
	 * @summary 取得選單對應的模組 config
	 * @request GET:/api/bscore/{menu_item_key}/get-module-config
	 * @secure
	 */
	bscoreGetModuleConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/get-module-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetSearchableDetail
	 * @summary 取得進階搜尋欄位
	 * @request GET:/api/bscore/{menu_item_key}/get-searchable
	 * @secure
	 */
	bscoreGetSearchableDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<AdvanceSearchable[], any>({
			path: `/api/bscore/${menuItemKey}/get-searchable`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般搜尋
	 *
	 * @tags bscore
	 * @name BscoreSearchCreate
	 * @request POST:/api/bscore/{menu_item_key}/search
	 * @secure
	 */
	bscoreSearchCreate = (menuItemKey: string, data: SearchRequest, params: RequestParams = {}) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreAdvanceSearchCreate
	 * @summary 進階搜尋
	 * @request POST:/api/bscore/{menu_item_key}/advance-search
	 * @secure
	 */
	bscoreAdvanceSearchCreate = (
		menuItemKey: string,
		data: AdvanceSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/advance-search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreateDetail
	 * @summary 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/create
	 * @secure
	 */
	bscoreCreateDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreEditDetail
	 * @summary 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/edit
	 * @secure
	 */
	bscoreEditDetail = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreate
	 * @summary 新增
	 * @request POST:/api/bscore/{menu_item_key}
	 * @secure
	 */
	bscoreCreate = (menuItemKey: string, data: GetDataCreate, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdate
	 * @summary 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreUpdate = (
		menuItemKey: string,
		id: string,
		data: GetDataUpdate,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDelete
	 * @summary 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreDelete = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCopyCreate
	 * @summary 複製
	 * @request POST:/api/bscore/{menu_item_key}/copy
	 * @secure
	 */
	bscoreCopyCreate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/copy`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDestroyAllCreate
	 * @summary 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/destroy-all
	 * @secure
	 */
	bscoreDestroyAllCreate = (
		menuItemKey: string,
		data: BscoreDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateRowCreate
	 * @summary 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/update-row
	 * @secure
	 */
	bscoreUpdateRowCreate = (
		menuItemKey: string,
		data: BscoreUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateListCreate
	 * @summary reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/update-list
	 * @secure
	 */
	bscoreUpdateListCreate = (
		{ menuItemKey, ...query }: BscoreUpdateListCreateRequestParams,
		data: BscoreUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Menu Item 對應的 Dashboard Config
	 *
	 * @tags bscore_dashboard
	 * @name BscoreGetDashboardConfigDetail
	 * @request GET:/api/bscore/{menu_item_key}/get-dashboard-config
	 * @secure
	 */
	bscoreGetDashboardConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/get-dashboard-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Metric Key 對應的 Metric
	 *
	 * @tags bscore_dashboard
	 * @name BscoreMetricDetail
	 * @request GET:/api/bscore/metric/{metric_key}
	 * @secure
	 */
	bscoreMetricDetail = (metricKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/metric/${metricKey}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelExportCreate
	 * @summary 匯出
	 * @request POST:/api/bscore/{menu_item_key}/excel-export
	 * @secure
	 */
	bscoreExcelExportCreate = (
		menuItemKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelExportCreate
	 * @summary SubList 匯出
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-export
	 * @secure
	 */
	bscoreSubListExcelExportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportCreate
	 * @summary 匯入
	 * @request POST:/api/bscore/{menu_item_key}/excel-import
	 * @secure
	 */
	bscoreExcelImportCreate = (
		menuItemKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportUploadUrlCreate
	 * @summary 取得匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreExcelImportUploadUrlCreate = (
		menuItemKey: string,
		data: BscoreExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportCreate
	 * @summary SubList 匯入
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import
	 * @secure
	 */
	bscoreSubListExcelImportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportUploadUrlCreate
	 * @summary 取得 SubList 匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreSubListExcelImportUploadUrlCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportExampleFileCreate
	 * @summary 下載匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-example-file
	 * @secure
	 */
	bscoreExcelImportExampleFileCreate = (
		menuItemKey: string,
		data: BscoreExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportExampleFileCreate
	 * @summary 下載 SubList 匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-example-file
	 * @secure
	 */
	bscoreSubListExcelImportExampleFileCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description 列表頁側邊欄，列出 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreSubMenuNaviList
	 * @request GET:/api/bscore/sub-menu-navi
	 * @secure
	 */
	bscoreSubMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuResource[];
				};
			},
			any
		>({
			path: `/api/bscore/sub-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 列表頁側邊欄，沒有 Menu 的 Menuitem 會在此列出
	 *
	 * @tags bscore_menu
	 * @name BscoreMenuItemNaviList
	 * @request GET:/api/bscore/menu-item-navi
	 * @secure
	 */
	bscoreMenuItemNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuItemResource[];
				};
			},
			any
		>({
			path: `/api/bscore/menu-item-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於列表頁側邊欄置底的 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreBottomMenuNaviList
	 * @request GET:/api/bscore/bottom-menu-navi
	 * @secure
	 */
	bscoreBottomMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						menus?: MenuResource[];
						menuItems?: MenuItemResource[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/bottom-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_menu
	 * @name BscoreInfoDetail
	 * @summary 提供前端 menu_item_key 的資訊
	 * @request GET:/api/bscore/{menu_item_key}/info
	 * @secure
	 */
	bscoreInfoDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台設定
	 *
	 * @tags bscore
	 * @name BscoreClientSettingsList
	 * @request GET:/api/bscore/client-settings
	 */
	bscoreClientSettingsList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/client-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsEditDetail
	 * @summary 設定編輯頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/settings/edit
	 * @secure
	 */
	bscoreSettingsEditDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsUpdate
	 * @summary 設定編輯頁更新
	 * @request PUT:/api/bscore/{menu_item_key}/settings
	 * @secure
	 */
	bscoreSettingsUpdate = (
		menuItemKey: string,
		data: BscoreSettingsUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListSearchCreate
	 * @summary SubList 一般搜尋
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/search
	 * @secure
	 */
	bscoreSubListSearchCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: SearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreateDetail
	 * @summary SubList 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/create
	 * @secure
	 */
	bscoreSubListCreateDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListEditDetail
	 * @summary SubList 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}/edit
	 * @secure
	 */
	bscoreSubListEditDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreate
	 * @summary SubList 新增
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}
	 * @secure
	 */
	bscoreSubListCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdate
	 * @summary SubList 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListUpdate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDelete
	 * @summary SubList 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListDelete = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDestroyAllCreate
	 * @summary SubList 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/destroy-all
	 * @secure
	 */
	bscoreSubListDestroyAllCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateRowCreate
	 * @summary SubList 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-row
	 * @secure
	 */
	bscoreSubListUpdateRowCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateListCreate
	 * @summary SubList reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-list
	 * @secure
	 */
	bscoreSubListUpdateListCreate = (
		{ menuItemKey, id, subListKey, ...query }: BscoreSubListUpdateListCreateRequestParams,
		data: BscoreSubListUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreTaskDetail
	 * @summary 取得 task 狀態
	 * @request GET:/api/bscore/task/{task}
	 * @secure
	 */
	bscoreTaskDetail = (task: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						status?: 'WAITING' | 'PROCESSING' | 'FINISH' | 'FAILED';
						result?: BscoreExportResult | BscoreImportResult;
					};
				};
			},
			any
		>({
			path: `/api/bscore/task/${task}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUploadUploadUrlCreate
	 * @summary 取得檔案上傳的 url
	 * @request POST:/api/bscore/upload/upload-url
	 * @secure
	 */
	bscoreUploadUploadUrlCreate = (
		data: BscoreUploadUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						filename?: string;
						/** 上傳用 Url */
						uploadUrl?: string;
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/upload/upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台版本資訊
	 *
	 * @tags bscore
	 * @name BscoreVersionList
	 * @request GET:/api/bscore/version
	 * @secure
	 */
	bscoreVersionList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/version`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
}
