import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { UserResource, V1AuthResetPasswordCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';
import storage from 'util/storage';

import { openModal, TOAST_TYPE, MODAL_CATEGORY } from './modal';
import { GetState, State as GlobalState } from './reducers';

interface FormItem {
	value: HTMLInputElement['value'];
	error?: string;
}

interface FormPayload {
	key: string;
	value: string;
	error?: string;
}

export interface UserFormProperty {
	lineName: FormItem;
	googleEmail: FormItem;
	name: FormItem;
	phone: FormItem;
	oldPassword: FormItem;
	newPassword: FormItem;
	newPasswordConfirm: FormItem;
	gender: FormItem;
	city: FormItem;
	address: FormItem;
	email: FormItem;
}

export const defaultUserForm = {
	lineName: { value: '', error: '' },
	googleEmail: { value: '', error: '' },
	name: { value: '', error: '' },
	phone: { value: '', error: '' },
	oldPassword: { value: '', error: '' },
	newPassword: { value: '', error: '' },
	newPasswordConfirm: { value: '', error: '' },
	gender: { value: '', error: '' },
	city: { value: '', error: '' },
	address: { value: '', error: '' },
	email: { value: '', error: '' },
};

/**
 * 取得會員資料
 */
export const getUserInfo = createAction('GET_USER_INFO', async () => {
	const { v1UserList } = api;
	try {
		const { data } = await v1UserList();
		return data?.data;
	} catch (error) {
		storage.removeToken();
		return {};
	}
});

/**
 * 會員資料表單初始化，自動帶入會員資料
 */
export const initUserForm = createAction<(dispatch: Dispatch, getState: GetState) => Promise<void>>(
	'INIT_USER_FORM',
() => async (dispatch, getState) => {
	const {
		member: { userInfo },
	} = getState();

	Object.keys(userInfo).forEach(key => {
		if (key === 'id') {
			return;
		}
		dispatch(
			updateUserForm({
				key,
				value: userInfo[key as keyof UserResource] as string,
			}),
		);
	});
},
);

/**
 * 會員中心更改密碼
 */
export const resetPassword = createAction('RESET_PASSWORD', () => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	const { member: { userForm, userInfo } } = getState();

	const formData = {
		phone: userInfo.phone,
		password: userForm.newPassword.value,
	};

	try {
		const { v1AuthResetPasswordCreate } = api;
		const { status } = await v1AuthResetPasswordCreate(formData as V1AuthResetPasswordCreateRequestPayload);

		if (status === 200) {
			dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '編輯成功' }));
			dispatch(getUserInfo());
		}
	} catch (e) {
		const msg = (e as { error: { errorCode: string, message: string } }).error?.message;
		dispatch(openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: msg }));
	}
});

/**
 * 更新會員資料表單
 */
export const updateUserForm = createAction(
	'UPDATE_USER_FORM',
	({ key, value, error }: FormPayload) => ({
		key,
		value: typeof value === 'string' ? (value || '').trim() : value,
		error: error || '',
	}),
);

/**
 * 送出更新的會員資訊
 */
export const submitUpdateUserInfo = createAction(
	'SUBMIT_UPDATE_USER_INFO',
	(userInfoItem: { key: string; value: string }, signup: boolean) => async (dispatch: Dispatch) => {
		const { v1UserUpdate } = api;
		try {
			await v1UserUpdate({ [userInfoItem.key]: userInfoItem.value });

			if (!signup) {
				dispatch(
					openModal({
						category: MODAL_CATEGORY.TOAST,
						type: TOAST_TYPE.SUCCESS,
						data: '編輯成功',
					}),
				);

				if (userInfoItem.key === 'email') dispatch(sendEmailVerification())
				return await dispatch(getUserInfo());
			}
			return null
		} catch (error) {
			return null;
		}
	},
);

/**
 * 發送驗證信
 */
export const sendEmailVerification = createAction(
	'SEND_EMAIL_VERIFICATION',
	() => async (dispatch: Dispatch) => {
		const { v1AuthSendEmailVerificationCreate } = api;
		try {
			await v1AuthSendEmailVerificationCreate();
			return await dispatch(openEmailModal());
		} catch (error) {
			return null;
		}
	},
);

export const openEmailModal = createAction('OPEN_EMAIL_MODAL', () => {});

export const closeEmailModal = createAction('CLOSE_EMAIL_MODAL', () => {});

/**
 * 清除會員資料表單
 */
export const clearUserForm = createAction('CLEAR_USER_FORM', () => defaultUserForm);

export interface State {
	loading: boolean;
	userInfo: UserResource;
	userForm: UserFormProperty;
	openEmailVerificationModal: boolean;
}

export const defaultState: State = {
	loading: false,
	userInfo: {},
	userForm: defaultUserForm,
	openEmailVerificationModal: false,
};

export const reducer = {
	member: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_INFO_FULFILLED: (state, action: Action<UserResource>) => ({
				...state,
				userInfo: action.payload,
				loading: false,
			}),

			UPDATE_USER_FORM: (state, action) => ({
				...state,
				userForm: {
					...state.userForm,
					[action.payload.key]: {
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),
			CLEAR_USER_FORM: (state, action) => ({
				...state,
				userForm: action.payload,
			}),
			OPEN_EMAIL_MODAL: state => ({
				...state,
				openEmailVerificationModal: true,
			}),
			CLOSE_EMAIL_MODAL: state => ({
				...state,
				openEmailVerificationModal: false,
			}),
		},
		defaultState,
	),
};

const memberActionsMap = {
	getUserInfo,
	updateUserForm,
	clearUserForm,
	submitUpdateUserInfo,
	resetPassword,
	sendEmailVerification,
	openEmailModal,
	closeEmailModal,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.member.userInfo,
	(state: GlobalState) => state.member.userForm,
	(state: GlobalState) => state.member.openEmailVerificationModal,
	(userInfo, userForm, openEmailVerificationModal) => ({
		userInfo,
		userForm,
		openEmailVerificationModal
	}),
);

type MemberSelector = ReturnType<typeof mapHooksToState>;
type MemberActionsMap = typeof memberActionsMap;

export const useMember = () =>
	useRedux<MemberSelector, MemberActionsMap>(mapHooksToState, memberActionsMap);
