import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { BannerResource } from 'util/api/swaggerApi/data-contracts';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export const getBanners = createAction('GET_BANNERS', async () => {
	try {
		const { v1BannerList } = api;
		const { data } = await v1BannerList();
		return data?.data;
	} catch (e) {
		return { banners: [] };
	}
});

export interface State {
	loading: boolean;
	banners: BannerResource[];
}

export const defaultState: State = {
	loading: false,
	banners: [],
};

export const reducer = {
	banners: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_BANNERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_BANNERS_FULFILLED: (state, action) => ({
				...state,
				banners: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const bannerActionsMap = {
	getBanners,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.banners.banners,
	banners => ({
		banners,
	}),
);

type BannerSelector = ReturnType<typeof mapHooksToState>;
type BannerActionsMap = typeof bannerActionsMap;

export const useBanner = () =>
	useRedux<BannerSelector, BannerActionsMap>(mapHooksToState, bannerActionsMap);
