// eslint-disable-next-line @typescript-eslint/no-explicit-any
import dayjs from 'dayjs';

export const isExist = (value: any) =>
	value !== null && value !== '' && typeof value !== 'undefined';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => !isExist(value);

export const sleep = (time: number) =>
	new Promise<void>(resolve => setTimeout(() => resolve(), time));

export const getYouTubeVideoLink = (youtubeLink: string | undefined): string => {
	const YouTubeIdRegExp =
		/(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^?&"'>]+)/;
	const match = youtubeLink?.match(YouTubeIdRegExp);
	const id = match ? match[1] : null;
	return `https://www.youtube.com/embed/${id}`;
};

export const getYouTubeVideoId = (youtubeLink: string | undefined): string | null => {
	const YouTubeIdRegExp =
		/(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^?&"'>]+)/;
	const match = youtubeLink?.match(YouTubeIdRegExp);
	const id = match ? match[1] : null;
	return id;
};

export const validatePhone = (value: string) => {
	const phoneRegex = /^(09)[0-9]{8}$/;
	return phoneRegex.test(value);
};

export const validatePassword = (value: string) => {
	const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
	return passwordRegex.test(value);
};

export const validateEmail = (value: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(value);
};

export const validateBirthYear = (value: string) => value.length === 4 && (Number(value) > 1900) && (Number(value) < (dayjs().year() + 1));

export const validateBirthMonth = (value: string) => value.length < 3 && (Number(value) > 0) && (Number(value) < 13);

export const validateBirthDate = (value: string) => value.length < 3 && (Number(value) > 0) && (Number(value) < 32);

export const getDateOnly = (isoDate: string) => dayjs(isoDate).format('YYYY/MM/DD');

export const editAfterLoaded = (selector: string, callback: (element: HTMLElement) => void) => {
	function queryElements(s: string, c: (element: HTMLElement) => void) {
		const elements = document.querySelectorAll(s);
		elements.forEach(item => c(item as HTMLElement));
	}
	queryElements(selector, callback);
	const observer = new MutationObserver(() => {
		queryElements(selector, callback);
	});
	observer.observe(document.documentElement, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
};
