import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getBanners } from 'models/banner';
import { getNewsListByCategory } from 'models/news';
import { getTopicsForHomePage } from 'models/topics';
import { getUserInfo } from 'models/member';

import storage from 'util/storage';
import { isExist } from 'util/helper';
import { NewsCategoryType } from '../enums/newsType';

import BlogsRoute from './Blogs';
import NewsRoute from './News';
import TopicsRoute from './Topics';
import TagsRoute from './Tags';
import PrivacyPolicyRoute from './PrivacyPolicy';
import CopyrightNoticeRoute from './CopyrightNotice';
import AboutRoute from './About';
import SearchRoute from './Search';
import MemberCentre from './MemberCentre';
import SignUpRoute from './SignUp';
import SignInRoute from './SignIn';
import VerifyRoute from './Verify';
import ForgetPasswordRoute from './ForgetPassword';
import ResetPasswordRoute from './ResetPassword';
import SocialSignUpRoute from './SocialSignUp';
import LineAuthRoute from './LineAuth';

const childrenHomeRoute: CustomRoute = {
	path: '',
	components: () => [import(/* webpackChunkName: 'home' */ './Home')],
	render: ([Home]) => <Home />,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Home');
		store.dispatch(getBanners());
		store.dispatch(getTopicsForHomePage());
		store.dispatch(
			getNewsListByCategory({ category_id: NewsCategoryType.EVENT, page: 1, count: 4 }),
		);
		store.dispatch(
			getNewsListByCategory({ category_id: NewsCategoryType.FOCUS, page: 1, count: 4 }),
		);

		const children = await next();
		console.log('on Enter Home / end');
		return children;
	},
};

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Root');
		const token = storage.getToken();
		if (isExist(token)) {
			store.dispatch(getUserInfo());
		}
		const children = await next();
		console.log('on Enter Root / end');
		return children;
	},
	children: [
		childrenHomeRoute,
		BlogsRoute,
		NewsRoute,
		TopicsRoute,
		TagsRoute,
		PrivacyPolicyRoute,
		CopyrightNoticeRoute,
		AboutRoute,
		SearchRoute,
		MemberCentre,
		SignUpRoute,
		SignInRoute,
		VerifyRoute,
		ForgetPasswordRoute,
		ResetPasswordRoute,
		SocialSignUpRoute,
		LineAuthRoute,
	],
};

export default routes;
