import React from 'react';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import ButtonScrollTop from 'components/atoms/ButtonScrollTop';

import Logo from 'images/ci/logo.inline.svg';
import IconFacebook from 'images/icon/socialMedia/facebook.inline.svg';
import IconInstagram from 'images/icon/socialMedia/instagram.inline.svg';
import IconLine from 'images/icon/socialMedia/line.inline.svg';
import IconYoutube from 'images/icon/socialMedia/youtube.inline.svg';

import styles from './index.module.css';

const Footer: React.FC = () => {
	const contactData = [
		{
			id: 1,
			label: 'TEL',
			value: '02-25575226',
		},
		{
			id: 2,
			label: 'FAX',
			value: '02-25575236',
		},
		{
			id: 3,
			label: 'MAIL',
			value: 'service@7don.com.tw',
		},
	];

	const navData = [
		{
			id: 1,
			label: '最新消息',
			url: '/news',
			linkType: 'internal',
		},
		{
			id: 2,
			label: '隱私權',
			url: '/privacy-policy',
			linkType: 'external',
		},
		{
			id: 3,
			label: '編輯精選',
			url: '/topics',
			linkType: 'internal',
		},
		{
			id: 4,
			label: '著作權宣告',
			url: '/copyright-notice',
			linkType: 'external',
		},
		{
			id: 5,
			label: '關於我們',
			url: '/about',
			linkType: 'external',
		},
	];

	const socialMediaData = [
		{
			id: 1,
			label: 'Facebook',
			icon: IconFacebook,
			url: 'https://www.facebook.com/profile.php?id=61563272786042',
		},
		{
			id: 2,
			label: 'Instagram',
			icon: IconInstagram,
			url: 'https://www.instagram.com/7don.tw/',
		},
		{
			id: 3,
			label: 'Line',
			icon: IconLine,
			url: 'https://maac.io/2HFex',
		},
		{
			id: 4,
			label: 'Youtube',
			icon: IconYoutube,
			url: 'https://www.youtube.com/channel/UCip9HTPR1XAmfhj3c3AoA3g',
		},
	];

	return (
		<footer className={styles.footer}>
			<div className={styles.footerContent}>
				<div className={styles.top}>
					<div className={styles.content}>
						<Icon className={styles.logo} src={Logo} />
						<p>10452 台北市中山區玉門街 1 號 CIT Ｈ 室</p>
						<div className={styles.block}>
							{contactData.map(contactItem => (
								<React.Fragment key={contactItem.id}>
									<p className={styles.label}>{contactItem.label}</p>
									<p>{contactItem.value}</p>
								</React.Fragment>
							))}
						</div>
					</div>
					<div className={styles.navWrapper}>
						{navData.map(navItem => (
							<Link
								key={navItem.id}
								className={styles.navItem}
								type={navItem.linkType as 'internal' | 'external'}
								to={navItem.url}
							>
								{navItem.label}
							</Link>
						))}
					</div>
				</div>
				<div className={styles.bottom}>
					<div className={styles.socialMedia}>
						{socialMediaData.map(socialMedia => (
							<Link
								key={socialMedia.id}
								className={styles.socialMediaItem}
								type="external"
								to={socialMedia.url}
							>
								<Icon className={styles.socialMediaIcon} src={socialMedia.icon} />
							</Link>
						))}
					</div>
					<p className={styles.copyright}>Copyright © 2024 7DON All Rights Reserved </p>
				</div>
			</div>
			<ButtonScrollTop />
		</footer>
	);
};

export default Footer;
