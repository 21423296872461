import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/hook/useLockScroll';

import { useHistory } from 'models/routing';
import { useGlobal } from 'models/global';

import Icon from 'components/atoms/Icon';

import IconSearch from 'images/icon/search.inline.svg';
import IconClose from 'images/icon/close.inline.svg';
import styles from './index.module.css';

const SearchModal: React.FC = () => {
	const history = useHistory();
	const searchBoxRef = useRef<HTMLDivElement>(null);
	const searchInputRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState('');
	const [{ isSearchBarOpen, isNavMenuOpen }, { setSearchBarClose }] = useGlobal();

	const handleClickSearchWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
		// 如果 e.target 的 parentElement 是 searchBoxRef.current 就不要關閉 search field
		if (searchBoxRef.current && searchBoxRef.current.contains(e.target as HTMLElement)) {
			return;
		}

		if (e.target !== searchBoxRef.current && e.target !== searchInputRef.current) {
			setSearchBarClose();
		}
	};

	const onSearch: () => void = () => {
		if (isExist(value)) {
			history.push(`/search?q=${value}`);
			history.go(0);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSearch();
		}
	};

	useEffect(() => {
		if (isNavMenuOpen || isSearchBarOpen) {
			fixBackgroundScrollY();
		} else {
			unfixBackgroundScrollY();
		}
		return () => {
			unfixBackgroundScrollY();
		};
	}, [isNavMenuOpen, isSearchBarOpen]);

	useEffect(() => {
		if (isNavMenuOpen) {
			setSearchBarClose();
		}
	}, [isNavMenuOpen]);

	return (
		<div role="presentation" onClick={handleClickSearchWrapper}>
			<div
				className={classnames(styles.searchBoxWrapper, {
					[styles.open]: isSearchBarOpen,
					[styles.close]: !isSearchBarOpen,
				})}
			>
				<div className={styles.searchInput} ref={searchBoxRef}>
					<Icon className={styles.close} src={IconClose} onClick={setSearchBarClose} />
					<div className={styles.inputWrapper} ref={searchInputRef}>
						<input
							type="text"
							value={value}
							placeholder="去動 關鍵字搜尋"
							onChange={e => setValue(e.target.value)}
							onKeyDown={handleKeyDown}
						/>
						<button type="button" className={styles.button} onClick={onSearch} aria-label="search">
							<Icon className={styles.icon} src={IconSearch} />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchModal;
