import { sleep } from 'util/helper';

export const fixBackgroundScrollY = () => {
	if (!document.body.classList.contains('no-scroll')) {
		document.body.classList.add('no-scroll');
	}

	if (!document.documentElement.classList.contains('no-scroll')) {
		document.documentElement.classList.add('no-scroll');
	}
};

export const unfixBackgroundScrollY = async () => {
	document.documentElement.classList.remove('no-scroll');

	if (document.body.classList.contains('no-scroll')) {
		document.body.classList.remove('no-scroll');
	}

	await sleep(500);
};
