import storage from 'util/storage';
import { parseQueryString } from './parseQueryString';

interface LineLoginState {
	info: any;
	query: string;
}

const { LINE_LOGIN_CLIENT_ID, SELF_HOST_ENDPOINT } = process.env;

const getRedirectUrl = () => `${SELF_HOST_ENDPOINT}/line-auth`;

export const storeInfoForLineState = (query: string) => {
	const info = storage.getItem('signin-callback-info');
	const state: LineLoginState = {
		info: info ? JSON.parse(info) : {},
		query,
	};

	return encodeURIComponent(JSON.stringify(state));
};

export const parseStateFromLineQuery = (): LineLoginState => {
	const { state } = parseQueryString();
	const defaultResult: LineLoginState = {
		info: {
			backPath: '',
		},
		query: '',
	};

	if (!state) {
		return defaultResult;
	}

	const decodeQuery = decodeURIComponent(state);
	const stateData = JSON.parse(decodeQuery) as LineLoginState;
	return stateData;
};

export const lineLoginLink = (query: string) =>
	`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_LOGIN_CLIENT_ID}&redirect_uri=${getRedirectUrl()}&state=${storeInfoForLineState(query)}&scope=profile%20openid%20email`;
