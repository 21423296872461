export enum NewsCategoryType {
	/* 賽事活動 */
	EVENT = 1,
	/* 焦點動態 */
	FOCUS = 2,
}

interface NewsCategoryTextMapProperty {
	text: string;
	key: string;
}

const newsCategoryTextMap: Record<NewsCategoryType, NewsCategoryTextMapProperty> = {
	[NewsCategoryType.EVENT]: { text: '賽事活動', key: 'event' },
	[NewsCategoryType.FOCUS]: { text: '焦點動態', key: 'focus' },
};

/**
 * 傳入最新消息分類 ID，轉換成對應文字
 * @param categoryId (1: 賽事活動, 2: 焦點動態)
 * @returns '賽事活動', '焦點動態'
 */
export const getNewsCategoryTextById = (categoryId: NewsCategoryType) =>
	newsCategoryTextMap[categoryId].text;
