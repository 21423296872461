import React from 'react';
import classnames from 'classnames';

import { useGlobal } from 'models/global';

import Icon from 'components/atoms/Icon';

import IconSearch from 'images/icon/search.inline.svg';
import styles from './index.module.css';

interface SearchProperty {
	className?: string;
}

const Search: React.FC<SearchProperty> = ({ className }) => {
	const [{ isNavMenuOpen }, { setNavClose, toggleSearchBarOpen }] = useGlobal();

	const handleClickSearchButton = () => {
		if (isNavMenuOpen) {
			setNavClose();
		}
		toggleSearchBarOpen();
	};

	return (
		<div className={classnames(styles.search, className)} role="presentation">
			<Icon className={styles.icon} src={IconSearch} onClick={handleClickSearchButton} />
		</div>
	);
};

export default Search;
